export const SUBSCRIPTIONS = {
  annual: {
    name: 'annual',
    totalPrice: 180,
  },
  quarterly: {
    name: 'quarterly',
    totalPrice: 50,
  },
  monthly: {
    name: 'monthly',
    totalPrice: 20,
  },
};

export const APP_STORE_LINKS = {
  Apple: 'https://apps.apple.com/us/app/strivers/id1483635996?ls=1',
  Android:
    'https://play.google.com/store/apps/details?id=com.waam_mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
};

export const ASSET_HOST = 'https://d3sivlfumj4n3y.cloudfront.net';

export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_USE = '/terms-of-use';
export const TERMS_OF_SALE = '/terms-of-sale';

export const STATIC_ROUTES = [
  '/',
  '/privacy-policy',
  '/list-of-local-entities',
  '/terms-of-use',
  '/terms-of-sale',
  '/workout-info',
];

export const DEFAULT_TRAINER = 'joshrogers';
