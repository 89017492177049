import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CardBodyForm from '../../components/molecule/CardBodyForm';

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const TransparentLoading = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const HeroPageSubheading = styled.div`
  color: #747679;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const MiniDivider = styled.div`
  border: 1px solid #e3e6e8;
  margin: 8px 0;
  width: 132px;
`;

export const CorporateLink = styled(Link)`
  align-items: center;
  color: #1e1f20;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 12px 0;
  text-decoration: none;
`;

export const SignUpLink = styled(Link)`
  color: #caa854;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
`;

export const HeroPageHeading = styled.div`
  color: #1e1f20;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 12px;
  text-align: center;
`;

export const NumberedItem = styled.div`
  color: #1e1f20;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 24px 0 16px 16px;
`;

/* old stripe */
export const CardWrapper = styled.div`
  display: flex;
  padding: 2px;
  border-radius: 2px;
  background-color: #ebf1f7;
  justify-content: center;
  flex-direction: column;
`;

export const CardElementStyled = styled.div`
  border-radius: 3px;
  padding: 4px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
`;

export const CardBodyElementStyled = styled(CardBodyForm)`
  background: #ffffff;
  border: ${(props) =>
    props.hasErrorMessage ? '1px solid #bc3d3d' : '1px solid #dadada'};
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 24px 16px 24px 12px;
  flex: 1;
`;

export const UpdateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const UpdateButton = styled(Button)`
  && {
    background: #1e1f20;
    border-radius: 4px;
    color: #ffffff;
    padding: 6px 2px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 0;
    text-transform: none;
    width: 100%;
    &:hover {
      background: #1e1f20;
      color: #ffffff;
    }
  }
`;

export const DisabledUpdateButton = styled(Button)`
  && {
    background: #f0f2f3;
    color: ##747679;
    padding: 6px 2px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 12px 0;
    text-transform: none;
    width: 100%;
    &:hover {
      background: #f0f2f3;
      color: ##747679;
    }
  }
`;

export const ErrorMessageContainer = styled.div`
  background: #bc3d3d;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 8px;
  margin: 2px 0 16px 0;
`;

export const PromoContainer = styled.div`
  align-items: center;
  background: #fdf8ec;
  border: 1px solid #daac57;
  border-radius: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
`;

export const PromoText = styled.p`
  color: #6d502e;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
`;

export const DisclaimerContainer = styled.div`
  color: #747679;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  margin-top: 20px;
  white-space: pre-wrap;
`;
