import * as ACTION from './constants';

const initialState = {
  isLoadingSpinner: false,
  spinnerText: null,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.UI_BASE:
      return { ...initialState };
    case ACTION.UI_LOADING_SPINNER_SET:
      return {
        ...state,
        isLoadingSpinner: action.spinnerState,
        spinnerText: action.spinnerText,
      };
    default:
      return state;
  }
}
