import styled from 'styled-components';
import * as theme from '../../theme';

import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const PageContainer = styled.div`
  background-color: ${theme.BASE_COLORS.white};
  width: 100%;
  height: 100%;
  font-family: 'Volte Regular';
  color: #00253f;
`;

export const MobileHeader = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${theme.BASE_COLORS.white};
    z-index: 1;
    padding: 8px 4px;
  }
`;

export const DesktopNav = styled.div`
  display: flex;
  padding: 10px 20px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 40px;
  margin: auto;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    max-width: 75em;
    padding-bottom: 20px;
  }
`;

export const Heading = styled.div`
  color: #00253f;
  font-size: 52px;
  font-weight: 600;
  padding: 40px 40px 20px;
  font-family: 'Volte Semibold';

  @media screen and (max-width: 800px) {
    font-size: 28px;
  }
`;

export const FAQSDetail = styled.div`
  width: 40%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const FAQSList = styled.div`
  width: 60%;
  margin-left: 40px;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-left: 0;
  }

  display: flex;
  min-height: 75vh;
  flex-direction: column;
  justify-content: space-between;
`;

export const TSJHR = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    border: 1px solid #caa854;
    background: #caa854;
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 28px auto 28px auto;
  }
`;

export const ListContainer = styled.ul`
  list-style-type: none;
  color: #00253f;
  padding: 0;
  margin: 0;
  padding-bottom: 20px;
`;

export const MobileFooter = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    background: ${theme.BASE_COLORS.white};
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    left: 0;
    margin-right: 16px;
    padding: 8px 0;
    position: absolute;
    right: 0;
    z-index: 1;
  }
`;

export const DesktopFooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: -20px;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const DesktopFooterImage = styled.img`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const LinkStyled = styled.a`
  color: #00253f;
  cursor: pointer;
  font-family: 'Volte Semibold';
  font-weight: 500;
  text-decoration: none;

  &:visited {
    color: #00253f;
  }
`;
export const AStyled = styled.a`
  color: #00253f;
  font-family: 'Volte Semibold';
  font-weight: 500;
  text-decoration: none;

  &:visited {
    color: #00253f;
  }
`;
export const BackButton = styled.a`
  color: #00253f;
  cursor: pointer;
  font-family: 'Volte Semibold';
  font-weight: 500;
  text-decoration: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:visited {
    color: #00253f;
  }
`;

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:first-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    borderTop: '1px solid #9A9A9A',
    marginBottom: -1,
    fontWeight: 500,
    fontFamily: 'Volte Semibold',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    '@media (max-width: 800px)': {
      padding: 0,
      margin: 0,
      marginLeft: '-12px',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: {
    order: -1,
  },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const QuestionStyled = styled.div`
  margin-left: 6px;
`;
export const AnswerStyled = styled.div`
  margin-left: 45px;
  @media screen and (max-width: 800px) {
    margin-left: 25px;
  }
`;
