import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import logo from '../../assets/images/strivers_logo_black.png';
import Back_Arrow from '../../assets/tsj/back_arrow.png';
import config from '../../core/config';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AnswerStyled,
  AStyled,
  BackButton,
  ContentContainer,
  DesktopFooterContainer,
  DesktopFooterImage,
  DesktopNav,
  FAQSDetail,
  FAQSList,
  Heading,
  LinkStyled,
  ListContainer,
  MobileFooter,
  MobileHeader,
  PageContainer,
  QuestionStyled,
  TSJHR,
} from './styles.js';

class TSJFAQs extends React.Component {
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <PageContainer>
        <MobileHeader>
          <BackButton onClick={this.goBack}>
            <img src={Back_Arrow} alt="back arrow" /> Back
          </BackButton>
        </MobileHeader>
        <DesktopNav>
          <BackButton onClick={this.goBack}>
            <img src={Back_Arrow} alt="back arrow" /> Back
          </BackButton>
        </DesktopNav>
        <Heading>FAQs</Heading>
        <ContentContainer>
          <FAQSDetail>
            <p>
              We&apos;re here to help you when we can. If the information on
              this page does not answer your questions, please reach out to
              Member Services.
            </p>
            <p style={{ marginBottom: 0 }}>
              Need Help? Contact Us at{' '}
              <LinkStyled href={`mailto:${config.contact.appsupport}`}>
                {config.contact.appsupport}
              </LinkStyled>
            </p>
          </FAQSDetail>
          <TSJHR />
          <FAQSList>
            <ListContainer>
              <Accordion>
                <AccordionSummary
                  style={{ borderTop: 'unset' }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <QuestionStyled>
                    What is Strivers x The St. James?
                  </QuestionStyled>
                </AccordionSummary>
                <AccordionDetails>
                  <AnswerStyled>
                    Strivers x The St. James is a digital community with
                    meticulously curated athletic performance, recovery and
                    wellness content, experiences, apparel and gear to help
                    people to maximize their human potential and pursue their
                    passions without constraint.
                  </AnswerStyled>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <QuestionStyled>
                    How do I get help with logging in to Strivers?
                  </QuestionStyled>
                </AccordionSummary>
                <AccordionDetails>
                  <AnswerStyled>
                    Contact customer support by email at{' '}
                    <LinkStyled href={`mailto:${config.contact.appsupport}`}>
                      {config.contact.appsupport}
                    </LinkStyled>
                    .
                  </AnswerStyled>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <QuestionStyled>
                    I am a Member at The St. James but I did not receive an
                    email with instructions to access the Strivers app. How do I
                    receive access?
                  </QuestionStyled>
                </AccordionSummary>
                <AccordionDetails>
                  <AnswerStyled>
                    Contact customer support by email at{' '}
                    <LinkStyled href={`mailto:${config.contact.appsupport}`}>
                      {config.contact.appsupport}
                    </LinkStyled>
                    .
                  </AnswerStyled>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <QuestionStyled>
                    How do I get access to the Strivers app if I am not
                    currently a member at The St. James?
                  </QuestionStyled>
                </AccordionSummary>
                <AccordionDetails>
                  <AnswerStyled>
                    Right now, Members and guests at The St. James are receiving
                    exclusive access. If you would like to learn more about
                    Strivers or The St. James membership, please email{' '}
                    <LinkStyled
                      href={`mailto:${config.contact.memberservices}`}
                    >
                      {config.contact.memberservices}
                    </LinkStyled>
                    .
                  </AnswerStyled>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <QuestionStyled>
                    Can I use the app at home and the gym?
                  </QuestionStyled>
                </AccordionSummary>
                <AccordionDetails>
                  <AnswerStyled>
                    Yes, the app has at home workouts with minimal equipment,
                    workouts with no equipment, as well as gym workouts.
                  </AnswerStyled>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <QuestionStyled>
                    How does Strivers differ from The St. James Mobile App?
                  </QuestionStyled>
                </AccordionSummary>
                <AccordionDetails>
                  <AnswerStyled>
                    Strivers{' '}
                    <b>
                      <u>does not</u>
                    </b>{' '}
                    replace The St. James app. Members should continue to use
                    The St. James app to check into the Health Club, schedule
                    classes, purchase sports programming and more. The Strivers
                    app is where members, guests and clients will find their
                    favorite workouts and programs to follow along. We will
                    continue to expand the kinds of experiences that we bring to
                    our members and community, both in the Complex and on your
                    phone. More information about The St. James app can be found{' '}
                    <AStyled href="https://www.thestjames.com/app">
                      here
                    </AStyled>
                    .
                  </AnswerStyled>
                </AccordionDetails>
              </Accordion>
            </ListContainer>
            <DesktopFooterContainer>
              <DesktopFooterImage
                src={logo}
                width={150}
                alt="Strivers x The St. James logo"
              />
            </DesktopFooterContainer>
          </FAQSList>
        </ContentContainer>
        <MobileFooter>
          <img src={logo} width={150} alt="Strivers x The St. James logo" />
        </MobileFooter>
      </PageContainer>
    );
  }
}

TSJFAQs.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
};

export default withRouter(TSJFAQs);
