import * as ACTION from './constants';

const initialState = {
  current_hero: {
    image: null,
    short: null,
  },
  download_app_page: {
    data: null,
    isLoading: false,
    loadedAt: null,
  },
  landing_page: {
    data: null,
    isLoading: false,
    loadedAt: null,
  },
  member_welcome_page: {
    data: null,
    isLoading: false,
    loadedAt: null,
  },
  privacy_policy: {
    data: null,
    isLoading: false,
    loadedAt: null,
  },
  subscription_page: {
    data: null,
    isLoading: false,
    loadedAt: null,
  },
  terms_of_sale: {
    data: null,
    isLoading: false,
    loadedAt: null,
  },
  terms_of_use: {
    data: null,
    isLoading: false,
    loadedAt: null,
  },
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.CONTENT_BASE:
      return { ...initialState };
    case ACTION.CONTENT_LOADING:
      return {
        ...state,
        [action.contentType]: {
          ...state[action.contentType],
          isLoading: true,
        },
      };
    case ACTION.CONTENT_SUCCESS:
      return {
        ...state,
        [action.contentType]: {
          data: action.payload,
          isLoading: false,
          loadedAt: new Date(),
        },
      };
    case ACTION.SET_HERO:
      return {
        ...state,
        current_hero: action.current_hero,
      };
    default:
      return state;
  }
}
