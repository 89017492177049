import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';

import CircularProgress from '@material-ui/core/CircularProgress';

import { getContent } from '../../modules/Content';
import { subscribe } from '../../modules/Subscription';
import { setCsrfToken } from '../../modules/Security';

import config from '../../core/config';
import { SUBSCRIPTIONS } from '../../core/constants';

import PlanButtons from '../../components/molecule/PlanButtons';
import FireSale from '../../assets/fire_sale.png';

import {
  CardBodyElementStyled,
  CardElementStyled,
  Content,
  DisabledUpdateButton,
  DisclaimerContainer,
  ErrorMessageContainer,
  HeroPageHeading,
  HeroPageSubheading,
  LoadingContainer,
  NumberedItem,
  PromoContainer,
  PromoText,
  TransparentLoading,
  UpdateButton,
  UpdateButtonContainer,
} from './styles.js';

class _PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      selectedPlan: SUBSCRIPTIONS.annual.name,
      complete: false,
      errorMessage: null,
    };
  }

  onCardElementChange = (changeObject) => {
    const { complete, error } = changeObject;

    const state = {
      errorMessage: error ? error.message : null,
      complete,
    };

    this.setState({ ...state });
  };

  async submit() {
    const {
      handleComplete,
      settings,
      stripe,
      subscribe,
      trainer: { trainer },
      updateSubscribing,
    } = this.props;
    const { selectedPlan } = this.state;

    updateSubscribing(true);

    // could be token or error
    const result = await stripe.createToken({ name: settings.name });

    if (result.token) {
      // send original promo code and revalidate on backend
      const promo_code =
        trainer && trainer.promo && trainer.promo.id && trainer.promoCode
          ? trainer.promoCode
          : null;

      // send referral code
      const referral_code =
        trainer && trainer.referralCode ? trainer.referralCode : null;

      try {
        await subscribe({
          plan: selectedPlan,
          promo_code: promo_code,
          token: result.token.id,
          referral_code: referral_code,
        });

        updateSubscribing(false);
        handleComplete();
      } catch (e) {
        this.setState({
          errorMessage: e.response.data.message,
        });
        updateSubscribing(false);
      }
    } else if (result.error) {
      this.setState({ errorMessage: result.error.message });
      updateSubscribing(false);
    } else {
      this.setState({
        errorMessage: 'Something went wrong. Try again.',
      });
      updateSubscribing(false);
    }
  }

  handlePlanSelection = (selectedPlan) => {
    this.setState({ selectedPlan });
  };

  render() {
    const { complete, errorMessage, selectedPlan } = this.state;
    const {
      subscriptionTerms,
      trainer: { trainer },
    } = this.props;

    let payAmount =
      trainer && trainer.prices && trainer.prices[selectedPlan]
        ? trainer.prices[selectedPlan]
        : SUBSCRIPTIONS[selectedPlan].totalPrice;

    // todo: update and test promo redesign
    if (trainer && trainer.promo && trainer.promo.id && trainer.promo.amount) {
      payAmount = payAmount - trainer.promo.amount;
    } else if (trainer && trainer.referralCode) {
      payAmount = payAmount - 10;
    }

    payAmount = Math.max(0, payAmount);

    return (
      <div
        className="checkout"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <NumberedItem>1. Select Subscription</NumberedItem>
        {trainer && trainer.promo && trainer.promo.id && trainer.promo.amount && (
          <PromoContainer>
            <img src={FireSale} alt="Promo" />
            <PromoText>
              You&apos;re getting ${trainer.promo.amount} off your first
              payment!
            </PromoText>
          </PromoContainer>
        )}
        {trainer && trainer.referralCode && (
          <PromoContainer>
            <img src={FireSale} alt="Promo" />
            <PromoText>
              You&apos;re getting $10 off your first payment!
            </PromoText>
          </PromoContainer>
        )}
        <PlanButtons
          order={[
            SUBSCRIPTIONS.monthly.name,
            SUBSCRIPTIONS.quarterly.name,
            SUBSCRIPTIONS.annual.name,
          ]}
          onSelect={this.handlePlanSelection}
          selectedPlan={selectedPlan}
          prices={trainer && trainer.prices}
        />
        <NumberedItem>2. Enter Payment Details</NumberedItem>
        <CardElementStyled>
          <CardBodyElementStyled
            onChange={this.onCardElementChange}
            hasErrorMessage={errorMessage}
          />
          {errorMessage && (
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          )}
          <UpdateButtonContainer>
            {!complete ? (
              <DisabledUpdateButton
                disabled={true}
              >{`Start Subscription - Pay $${payAmount} Now`}</DisabledUpdateButton>
            ) : (
              <UpdateButton onClick={this.submit}>
                {`Start Subscription - Pay $${payAmount} Now`}
              </UpdateButton>
            )}
          </UpdateButtonContainer>
        </CardElementStyled>
        <DisclaimerContainer
          dangerouslySetInnerHTML={{ __html: subscriptionTerms }}
        />
      </div>
    );
  }
}

_PaymentForm.propTypes = {
  handleComplete: PropTypes.func,
  settings: PropTypes.object,
  stripe: PropTypes.object, // todo
  subscribe: PropTypes.func,
  subscriptionTerms: PropTypes.string,
  trainer: PropTypes.object,
  updateSubscribing: PropTypes.func,
};

const PaymentForm = injectStripe(_PaymentForm);

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
      subscribing: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const {
      cookies,
      getContent,
      security: { csrftoken },
      setCsrfToken,
    } = this.props;

    getContent(
      'subscription_page',
      ['hero', 'landing_page_content'],
      ['subscription_terms'],
    );

    const csrftoken_cookie = cookies.get(config.api.csrfCookieName);

    // if no token in state and there is a cookie, reset from cookie
    if (csrftoken_cookie && csrftoken_cookie !== csrftoken) {
      setCsrfToken(csrftoken_cookie);
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleComplete = () => {
    const { history } = this.props;
    history.push('/download');
  };

  updateSubscribing = (subscribing) => {
    this.setState({ subscribing });
  };

  renderPageHeading() {
    const {
      content: {
        subscription_page: {
          data: {
            landing_page_content: { page_heading },
          },
        },
      },
    } = this.props;

    return (
      <div>
        {page_heading.page_heading_options === 'Logo' ? (
          <img src={page_heading.logo.url} />
        ) : (
          <div>{page_heading.heading}</div>
        )}
      </div>
    );
  }

  render() {
    const {
      content: {
        subscription_page: { data, isLoading },
      },
      settings,
      subscribe,
      trainer,
    } = this.props;
    const { subscribing } = this.state;

    return isLoading || data == null ? (
      <LoadingContainer>
        <CircularProgress size={70} style={{ color: '#caa854' }} />
      </LoadingContainer>
    ) : (
      <>
        <Content>
          <HeroPageHeading>{this.renderPageHeading()}</HeroPageHeading>
          <HeroPageSubheading>
            {data.landing_page_content.page_subheading}
          </HeroPageSubheading>
          <StripeProvider apiKey={config.stripe.apiKey}>
            <Elements>
              <PaymentForm
                handleComplete={this.handleComplete}
                settings={settings}
                subscribe={subscribe}
                subscriptionTerms={data.subscription_terms}
                trainer={trainer}
                updateSubscribing={this.updateSubscribing}
              />
            </Elements>
          </StripeProvider>
        </Content>
        {subscribing && (
          <TransparentLoading>
            <CircularProgress size={70} style={{ color: '#caa854' }} />
          </TransparentLoading>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authState,
    content: state.contentState,
    security: state.securityState,
    settings: state.settingsState,
    trainer: state.trainerState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent: getContent,
      setCsrfToken: setCsrfToken,
      subscribe: subscribe,
    },
    dispatch,
  );
}

Subscription.propTypes = {
  content: PropTypes.object,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  getContent: PropTypes.func,
  history: ReactRouterPropTypes.history.isRequired,
  security: PropTypes.shape({
    csrftoken: PropTypes.string,
  }),
  setCsrfToken: PropTypes.func,
  settings: PropTypes.object,
  subscribe: PropTypes.func,
  trainer: PropTypes.shape({
    isRetrieving: PropTypes.bool,
  }),
};

export default compose(
  withRouter,
  withCookies,
  connect(mapStateToProps, mapDispatchToProps),
)(Subscription);
