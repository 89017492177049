import * as ACTION from './constants';

const initialState = {
  accountData: null,
  isConfirmingEmail: false,
  isChangingUsername: false,
  isChangingEmail: false,
  isFetching: false,
  isUpdating: false,
  tsjMemberError: null,
  tsjMemberValidated: false,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.SETTINGS_BASE:
      return { ...initialState };
    case ACTION.GET_USER_DATA:
      return { ...state, isFetching: true };
    case ACTION.UPDATING_USER_DATA:
      return { ...state, isUpdating: true };
    case ACTION.SET_USER_DATA:
      return {
        ...state,
        isUpdating: false,
        accountData: action.payload,
      };
    case ACTION.CONFIRMING_EMAIL:
      return { ...state, isConfirmingEmail: true };
    case ACTION.CONFIRMED_EMAIL_SUCCESS:
      return { ...state, isConfirmingEmail: false };
    case ACTION.CONFIRMED_EMAIL_ERROR:
      return { ...state, isConfirmingEmail: false };
    case ACTION.CHANGING_USERNAME:
      return { ...state, isChangingUsername: true };
    case ACTION.CHANGED_USERNAME_SUCCESS:
      return { ...state, isChangingUsername: false };
    case ACTION.CHANGED_USERNAME_ERROR:
      return { ...state, isChangingUsername: false };
    case ACTION.CHANGING_EMAIL:
      return { ...state, isChangingEmail: true };
    case ACTION.CHANGED_EMAIL_SUCCESS:
      return { ...state, isChangingEmail: false };
    case ACTION.CHANGED_EMAIL_ERROR:
      return { ...state, isChangingEmail: false };
    case ACTION.VALIDATED_TSJ_MEMBER:
      return {
        ...state,
        tsjMemberError: null,
        tsjMemberValidated: true,
      };
    case ACTION.TSJ_MEMBER_ERROR:
      return {
        ...state,
        tsjMemberError: action.error,
        tsjMemberValidated: false,
      };
    case ACTION.CLEAR_TSJ_MEMBER_ERROR:
      return {
        ...state,
        tsjMemberError: null,
        tsjMemberValidated: false,
      };
    default:
      return state;
  }
}
