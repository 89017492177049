import axios from 'axios';
import store from '../store';
import config from './config';

const Axios = axios.create({
  baseURL: config.api.base,
  withCredentials: true,
});

Axios.interceptors.request.use((axiosConfig) => {
  if (typeof store.getState().authState !== 'undefined') {
    const auth = store.getState().authState;
    const userToken = auth.userData ? auth.userData.token : undefined;
    if (userToken) {
      Object.assign(axiosConfig.headers, {
        Authorization: `Token ${userToken}`,
      });
    }
  }
  if (typeof store.getState().securityState !== 'undefined') {
    const security = store.getState().securityState;
    const csrftoken = security.csrftoken;
    if (csrftoken) {
      Object.assign(axiosConfig.headers, {
        'X-CSRFToken': csrftoken,
      });
    }
  }
  return axiosConfig;
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.config && error.response) {
      const status = error.response.status || null;
      if (status === 401) {
        /* refresh_token */
      }
    }

    return Promise.reject(error);
  },
);

export { Axios };
