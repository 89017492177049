import * as ACTION from './constants';
import * as apiService from '../../services/apiServices';
import { setTrainer } from '../Trainer';
import { data as trainer_data } from '../../core/trainer_data';

export const isSubscribing = () => ({ type: ACTION.SUBSCRIBING });
export const subscriptionSuccess = () => ({
  type: ACTION.SUBSCRIPTION_SUCCESS,
});
export const subscriptionError = (error = null) => ({
  type: ACTION.SUBSCRIPTION_ERROR,
  error,
});
export const isFetchingSubscription = () => ({ type: ACTION.GET_SUBSCRIPTION });
export const setSubscription = (payload = null) => ({
  type: ACTION.SET_SUBSCRIPTION,
  payload,
});
export const isCanceling = () => ({ type: ACTION.CANCELING });
export const cancelSuccess = () => ({ type: ACTION.CANCEL_SUCCESS });
export const cancelError = (error = null) => ({
  type: ACTION.CANCEL_ERROR,
  error,
});

export const setReferralCode = (code = null) => ({
  type: ACTION.SET_REFERRAL_CODE,
  code,
});
export const isValidatingReferralCode = () => ({
  type: ACTION.VALIDATING_REFERRAL_CODE,
});
export const referralCodeError = (error = null) => ({
  type: ACTION.REFERRAL_CODE_ERROR,
  error,
});

export const subscribe = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(isSubscribing());
    apiService
      .subscribe(data)
      .then((response) => {
        dispatch(subscriptionSuccess());
        dispatch(setSubscription(response));
        resolve(response);
      })
      .catch((error) => {
        dispatch(subscriptionError(error));
        reject(error);
      });
  });
};

export const getSubscription = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(isFetchingSubscription());
    apiService
      .getSubscription()
      .then((response) => {
        dispatch(setSubscription(response));
        resolve(response);
      })
      .catch((error) => {
        dispatch(subscriptionError(error));
        reject(error);
      });
  });
};

export const cancelSubscription = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(isCanceling());
    apiService
      .cancelSubscription(data)
      .then((response) => {
        dispatch(cancelSuccess());
        dispatch(setSubscription(response));
        resolve(response);
      })
      .catch((error) => {
        dispatch(cancelError(error));
        reject(error);
      });
  });
};

export const validateReferralCode = (code) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(isValidatingReferralCode());
    apiService
      .validateReferralCode(code)
      .then((response) => {
        dispatch(
          setTrainer({
            referralCode: response.code,
            ...response.trainer,
            site_data: {
              slug: response.trainer.channel_slug,
              ...trainer_data.sites[response.trainer.channel_slug],
            },
            ...trainer_data[response.trainer.channel_slug],
          }),
        );
        dispatch(setReferralCode(response.code));
        resolve(response.code);
      })
      .catch((error) => {
        dispatch(referralCodeError(error));
        reject(error);
      });
  });
};
