import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const HeroPageSubheading = styled.div`
  color: #747679;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const MiniDivider = styled.div`
  border: 1px solid #e3e6e8;
  margin: 16px 0;
  width: 132px;
`;

export const HeroPageHeading = styled.div`
  color: #1e1f20;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 12px;
  text-align: center;
`;

export const NeedHelp = styled.div`
  color: #1e1f20;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
`;

export const ContactUsLink = styled.a`
  color: #1e1f20;
  font-weight: 600;
`;

export const GoLink = styled(Link)`
  align-items: center;
  background: #1e1f20;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  line-height: 24px;
  margin-top: 24px;
  padding: 12px 0;
  text-decoration: none;
  width: 100%;
`;
