import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  lookupTrainerBySite,
  lookupTrainerBySlug,
  setTrainerFromStore,
} from '../modules/Trainer';
import LoadingScreen from '../components/molecule/LoadingScreen';
import { setMetaData } from '../modules/Meta';
import { validateReferralCode } from '../modules/Subscription';

import LayoutProvider from './LayoutProvider';

function querystring(name, url = window.location.href) {
  name = name.replace(/[[]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

class Layout extends React.Component {
  UNSAFE_componentWillMount() {
    const { setMetaData } = this.props;

    const metas = document.getElementsByTagName('meta');
    const metadata = {};
    for (let i = 0; i < metas.length; i++) {
      const metaKey =
        metas[i].getAttribute('name') || metas[i].getAttribute('property');
      if (metaKey) {
        metadata[metaKey] = metas[i].getAttribute('content');
      }
    }

    if (Object.keys(metadata).length > 0) {
      setMetaData(metadata);
    }
  }

  async componentDidMount() {
    const {
      location,
      lookupTrainerBySite,
      lookupTrainerBySlug,
      settingsState,
      setTrainerFromStore,
      validateReferralCode,
    } = this.props;

    const hostparts = window.location.hostname.split('.');
    const pathname = location.pathname.split('/');

    if (hostparts.length === 3) {
      const slug = hostparts.shift();

      if (slug === 'web') {
        const trainerState = JSON.parse(localStorage.getItem('trainerState'));

        if (!trainerState) {
          const query_slug = querystring('trainer');
          if (query_slug) {
            await lookupTrainerBySlug(query_slug);
          } else if (settingsState.trainer) {
            setTrainerFromStore(settingsState.trainer);
          } else if (pathname[1]) {
            if (pathname[1] === 'sites' && pathname[2]) {
              const promoCode = pathname[3] || undefined;
              await lookupTrainerBySite(pathname[2], promoCode);
            } else if (pathname[1] === 'join' && pathname[2]) {
              await validateReferralCode(pathname[2]);
            }
          }
        } else {
          // 'sites' path overrides local storage
          if (pathname[1] === 'sites' && pathname[2]) {
            const promoCode = pathname[3] || undefined;
            await lookupTrainerBySite(pathname[2], promoCode);
          } else if (pathname[1] === 'join' && pathname[2]) {
            await validateReferralCode(pathname[2]);
          } else {
            setTrainerFromStore(trainerState);
          }
        }
      } else if (slug === 'www' && /^\/sites/.test(window.location.pathname)) {
        window.location.href = window.location.href.replace('www', 'web');
      } else if (slug !== 'www') {
        await lookupTrainerBySlug(slug);
      }
    }
  }

  render() {
    const { childProps, globalUI } = this.props;

    return globalUI.isLoadingSpinner ? (
      <LoadingScreen />
    ) : (
      <LayoutProvider childProps={childProps} />
    );
  }
}

function mapStateToProps(state) {
  return {
    globalUI: state.globalUIState,
    settingsState: state.settingsState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      lookupTrainerBySite,
      lookupTrainerBySlug,
      setMetaData,
      setTrainerFromStore,
      validateReferralCode,
    },
    dispatch,
  );
}

Layout.propTypes = {
  childProps: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    handleLogout: PropTypes.func,
  }),
  globalUI: PropTypes.shape({
    isLoadingSpinner: PropTypes.bool,
  }),
  location: ReactRouterPropTypes.location,
  lookupTrainerBySite: PropTypes.func,
  lookupTrainerBySlug: PropTypes.func,
  setMetaData: PropTypes.func,
  settingsState: PropTypes.shape({
    trainer: PropTypes.object,
  }),
  setTrainerFromStore: PropTypes.func,
  validateReferralCode: PropTypes.func,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Layout);
