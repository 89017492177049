const config = {
  api: {
    base: process.env.REACT_APP_API_BASE,
    csrfCookieName: process.env.REACT_APP_CSRF_COOKIE_NAME,
  },
  contentStack: {
    apiKey: process.env.REACT_APP_CONTENTSTACK_APIKEY,
    deliveryToken: process.env.REACT_APP_CONTENTSTACK_DELIVERY_TOKEN,
    environment: process.env.REACT_APP_CONTENTSTACK_ENVIRONMENT,
  },
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_API_KEY,
  },
  contact: {
    memberservices: process.env.REACT_APP_MEMBER_SERVICES_EMAIL,
    appsupport: process.env.REACT_APP_APP_SUPPORT_EMAIL,
  },
  sso: {
    url: process.env.REACT_APP_STJ_OIDC_URL,
    clientId: process.env.REACT_APP_STJ_OIDC_CLIENT_ID,
    redirectUri: process.env.REACT_APP_STJ_OIDC_REDIRECT_URI,
  },
  base_href: process.env.REACT_APP_BASE_HREF,
};

export default config;
