import * as ACTION from './constants';

// ACTION CREATORS
export const securityBase = () => ({ type: ACTION.SECURITY_BASE });

export const setCsrfToken =
  (csrftoken = undefined) =>
  (dispatch) => {
    dispatch({
      type: ACTION.SECURITY_CSRF_TOKEN_SET,
      csrftoken,
    });
  };
