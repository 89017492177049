import * as ACTION from './constants';

const initialState = {
  data: null,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.META_BASE:
      return { ...initialState };
    case ACTION.SET_META_DATA:
      return {
        data: { ...action.payload },
      };
    default:
      return state;
  }
}
