import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { CircularProgress } from '@material-ui/core';

import { getContent } from '../modules/Content';

import { OIDC } from '../pages/OIDC';
import { LandingPage } from '../pages/LandingPage';
import { Subscription } from '../pages/Subscription';
import { DownloadApp } from '../pages/DownloadApp';
import { NotFound } from '../pages/NotFound';

import { FAQs } from '../pages/TSJ';
import { PrivacyPolicy } from '../pages/PrivacyPolicy';
import { TermsOfSale } from '../pages/TermsOfSale';
import { TermsOfUse } from '../pages/TermsOfUse';
import { Settings } from '../pages/Settings';
import { Trainer } from '../pages/Trainer';

import logo_black from '../assets/images/strivers_logo_black.png';
import logo_white from '../assets/images/strivers_logo_white.png';

import {
  CuriousContainer,
  FAQsLink,
  FooterContainer,
  FooterInner,
  HeroContentInner,
  HeroContentWrapper,
  HeroColumn,
  HeroContentContainer,
  HeroImage,
  LoadingContainer,
  LogoImage,
  MainContainer,
  TermsLink,
} from './styles.js';

const standaloneRoutes = [
  '/oidc',
  '/privacy-policy',
  '/settings',
  '/terms-of-sale',
  '/terms-of-use',
  '/thestjames/faqs',
];

class LayoutProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentType: 'landing_page',
      trainerHero: null,
      useTrainerHero: false,
      width: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const { getContent } = this.props;
    const { contentType } = this.state;
    getContent(contentType, ['hero', 'landing_page_content']);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  renderHeroImage() {
    const {
      content,
      location: { pathname },
      trainer: { trainer },
    } = this.props;
    const { width } = this.state;

    const heroImage =
      trainer && trainer.site_data && trainer.site_data.image
        ? trainer.site_data.image
        : width < 800 && pathname !== '/'
        ? content.current_hero.short
        : content.current_hero.image;

    return <HeroImage src={heroImage} />;
  }

  render() {
    const { contentType, width } = this.state;
    const {
      auth: { isAuthenticated },
      childProps,
      content,
      location,
      trainer: { isRetrieving, trainer },
    } = this.props;

    const { data, isLoading } = content[contentType];

    const standaloneRoute = standaloneRoutes.indexOf(location.pathname) >= 0;

    return isLoading || isRetrieving || data == null ? (
      <LoadingContainer>
        <CircularProgress size={70} style={{ color: '#caa854' }} />
      </LoadingContainer>
    ) : standaloneRoute ? (
      <Switch>
        <Route exact path="/oidc">
          <OIDC />
        </Route>
        <Route exact path="/settings">
          {isAuthenticated ? (
            <Settings {...childProps} />
          ) : (
            <Redirect
              to={`/login?redirect=${location.pathname}${location.search}`}
            />
          )}
        </Route>
        <Route exact path="/thestjames/faqs">
          <FAQs {...childProps} />
        </Route>
        <Route exact path="/privacy-policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms-of-sale">
          <TermsOfSale />
        </Route>
        <Route exact path="/terms-of-use">
          <TermsOfUse />
        </Route>
      </Switch>
    ) : (
      <MainContainer pathname={location.pathname}>
        <HeroColumn>
          {this.renderHeroImage()}
          {location.pathname !== '/' && width < 800 && (
            <LogoImage src={logo_white} alt="Strivers x The St. James" />
          )}
        </HeroColumn>
        <HeroColumn>
          <HeroContentContainer pathname={location.pathname}>
            <HeroContentWrapper>
              <HeroContentInner>
                <TransitionGroup>
                  {/* todo: figure out smooth transition */}
                  {/* http://reactcommunity.org/react-transition-group/with-react-router */}
                  {/* https://reactrouter.com/web/example/animated-transitions */}
                  <CSSTransition timeout={0}>
                    <Switch location={location}>
                      <Route exact path="/">
                        <LandingPage {...childProps} />
                      </Route>
                      <Route exact path="/login">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/email-login">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/signup">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/email-signup">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/thestjames/welcome">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/subscription">
                        {isAuthenticated ? (
                          <Subscription {...childProps} />
                        ) : (
                          <Redirect
                            to={`/login?redirect=${location.pathname}${location.search}`}
                          />
                        )}
                      </Route>
                      <Route exact path="/download">
                        <DownloadApp {...childProps} />
                      </Route>
                      <Route exact path="/password/forgot">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/password/reset">
                        <Redirect to="/" />
                      </Route>
                      <Route exact path="/settings/confirm_email">
                        <Redirect to="/" />
                      </Route>
                      <Route path="/sites/:site">
                        <Trainer {...childProps} trainer={trainer} />
                      </Route>
                      <Route exact path="/join/:code">
                        <Trainer {...childProps} trainer={trainer} />
                      </Route>
                      <Route component={NotFound} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </HeroContentInner>
            </HeroContentWrapper>
          </HeroContentContainer>
        </HeroColumn>
        <FooterContainer>
          <FooterInner>
            <Link to="/">
              <img
                width={150}
                src={logo_black}
                alt="Strivers x The St. James"
              />
            </Link>
            <CuriousContainer>
              <span>Curious about the App?</span>
              &nbsp;<FAQsLink to="/thestjames/faqs">Visit the FAQs</FAQsLink>
            </CuriousContainer>
            <TermsLink to="/terms-of-use">Terms &amp; Conditions</TermsLink>
          </FooterInner>
        </FooterContainer>
      </MainContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authState,
    content: state.contentState,
    trainer: state.trainerState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent: getContent,
    },
    dispatch,
  );
}

LayoutProvider.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
  }),
  childProps: PropTypes.object,
  content: PropTypes.object,
  getContent: PropTypes.func,
  location: ReactRouterPropTypes.location,
  trainer: PropTypes.shape({
    isRetrieving: PropTypes.bool,
    trainer: PropTypes.shape({
      channel_slug: PropTypes.string,
      site_data: PropTypes.shape({
        image: PropTypes.string,
      }),
    }),
  }),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(LayoutProvider);
