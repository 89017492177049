export const NAMESPACE = 'settings';
export const SETTINGS_BASE = `${NAMESPACE}/SETTINGS_BASE`;
export const GET_USER_DATA = `${NAMESPACE}/GET_USER_DATA`;
export const SET_USER_DATA = `${NAMESPACE}/SET_USER_DATA`;
export const UPDATING_USER_DATA = `${NAMESPACE}/UPDATING_USER_DATA`;
export const CONFIRMING_EMAIL = `${NAMESPACE}/CONFIRMING_EMAIL`;
export const CONFIRMED_EMAIL_SUCCESS = `${NAMESPACE}/CONFIRMED_EMAIL_SUCCESS`;
export const CONFIRMED_EMAIL_ERROR = `${NAMESPACE}/CONFIRMED_EMAIL_ERROR`;
export const CHANGING_USERNAME = `${NAMESPACE}/CHANGING_USERNAME`;
export const CHANGED_USERNAME_SUCCESS = `${NAMESPACE}/CHANGED_USERNAME_SUCCESS`;
export const CHANGED_USERNAME_ERROR = `${NAMESPACE}/CHANGED_USERNAME_ERROR`;
export const CHANGING_EMAIL = `${NAMESPACE}/CHANGING_EMAIL`;
export const CHANGED_EMAIL_SUCCESS = `${NAMESPACE}/CHANGED_EMAIL_SUCCESS`;
export const CHANGED_EMAIL_ERROR = `${NAMESPACE}/CHANGED_EMAIL_ERROR`;
export const VALIDATED_TSJ_MEMBER = `${NAMESPACE}/VALIDATED_TSJ_MEMBER`;
export const TSJ_MEMBER_ERROR = `${NAMESPACE}/TSJ_MEMBER_ERROR`;
export const CLEAR_TSJ_MEMBER_ERROR = `${NAMESPACE}/CLEAR_TSJ_MEMBER_ERROR`;
