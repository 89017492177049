import React from 'react';
import { Log, OidcClient } from 'oidc-client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { getContent } from '../../modules/Content';
import SSOLoginButton from '../../components/molecule/SSOLoginButton';

import config from '../../core/config';

import {
  Content,
  HeroPageSubheading,
  LinkStyled,
  LoadingContainer,
} from './styles.js';

class NotFound extends React.Component {
  componentDidMount() {
    const { getContent } = this.props;
    getContent('landing_page', ['hero', 'landing_page_content']);
  }

  renderPageHeading() {
    const {
      content: {
        landing_page: {
          data: {
            landing_page_content: { page_heading },
          },
        },
      },
    } = this.props;

    return (
      <div>
        {page_heading.page_heading_options === 'Logo' ? (
          <img src={page_heading.logo.url} alt="Strivers Logo" />
        ) : (
          <div>{page_heading.heading}</div>
        )}
      </div>
    );
  }

  async login() {
    Log.logger = console;
    Log.level = Log.INFO;

    const settings = {
      authority: `${config.sso.url}/api/oidc`,
      client_id: config.sso.clientId,
      loadUserInfo: false,
      redirect_uri: config.sso.redirectUri,
      silent_redirect_uri: config.sso.redirectUri,
      response_type: 'code',
      response_mode: 'query',
      scope: 'openid email profile offline_access',
      prompt: 'consent',
      post_logout_redirect_uri: config.base_href,
    };

    const oidcClient = new OidcClient(settings);
    const signinRequest = await oidcClient.createSigninRequest();
    window.location.href = signinRequest.url;
  }

  render() {
    const {
      content: {
        landing_page: { data, isLoading },
      },
    } = this.props;

    return isLoading || data == null ? (
      <LoadingContainer>
        <CircularProgress size={70} style={{ color: '#caa854' }} />
      </LoadingContainer>
    ) : (
      <Content>
        {this.renderPageHeading()}
        <HeroPageSubheading>
          Sorry, the page you are looking for does not exist.
          <br />
          <br />
          Try logging in below or Contact Us at{' '}
          <LinkStyled href={`mailto:${config.contact.appsupport}`}>
            {config.contact.appsupport}
          </LinkStyled>
        </HeroPageSubheading>
        <SSOLoginButton />
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.contentState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent: getContent,
    },
    dispatch,
  );
}

NotFound.propTypes = {
  content: PropTypes.object,
  getContent: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
