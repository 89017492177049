import { Axios } from '../core/axios';

// ------------------ ACCOUNT ------------------
export function getAccountSettings(params, options) {
  return Axios.get('/account/settings/', params, options).then((response) => {
    return response.data;
  });
}

export function signOut(token) {
  return Axios.delete(`/tokens/${token}/`).then((response) => {
    return response.data;
  });
}

export function lookupTrainerBySlug(slug, params, options) {
  return Axios.get(`/trainers/${slug}/`, params, options).then(
    (response) => {
      return response.data;
    },
    (error) => {
      // if error.response and error.response.status is 404, trainer not found
      // in case we want to display something different than generic message
      return error;
    },
  );
}

// ------------------ SUBSCRIPTION ---------------
export function subscribe(params, options) {
  return Axios.post('/subscribe/', params, options).then((response) => {
    return response.data;
  });
}

export function getSubscription(params, options) {
  return Axios.get('/subscription/', params, options).then((response) => {
    return response.data;
  });
}

export function cancelSubscription(params, options) {
  return Axios.post('/cancel-subscription/', params, options).then(
    (response) => {
      return response.data;
    },
  );
}

export function getCustomer(params, options) {
  return Axios.get('/customer/', params, options).then((response) => {
    return response.data;
  });
}

export function updateCustomer(params, options) {
  return Axios.patch('/customer/', params, options).then((response) => {
    return response.data;
  });
}

export function contactUs(params, options) {
  return Axios.post('/contact-us/', params, options).then((response) => {
    return response.data;
  });
}

export function validatePromoCode(promoCode, params, options) {
  return Axios.get(`/promocodes/${promoCode}/`, params, options).then(
    (response) => {
      return response.data;
    },
    (error) => {
      // if error.response and error.response.status is 404, promoCode not found
      // in case we want to display something different than generic message
      return error;
    },
  );
}

export function validateReferralCode(referralCode, params, options) {
  return Axios.get(`/referralcode/${referralCode}/`, params, options).then(
    (response) => {
      return response.data;
    },
    (error) => {
      // if error.response and error.response.status is 404, referralCode not found
      // in case we want to display something different than generic message
      return error;
    },
  );
}
