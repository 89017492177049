import * as ACTION from './constants';
import config from '../../core/config';
import contentstack from 'contentstack';

// 10 minutes in milliseconds
const CONTENT_TIMEOUT = 600000;

// ACTION CREATORS
export const contentBase = () => ({ type: ACTION.CONTENT_BASE });
export const contentLoading = (contentType) => ({
  type: ACTION.CONTENT_LOADING,
  contentType,
});
export const contentLoaded = (contentType, payload = null) => ({
  type: ACTION.CONTENT_SUCCESS,
  contentType,
  payload,
});
export const setHero = (current_hero) => ({
  type: ACTION.SET_HERO,
  current_hero,
});

const Stack = contentstack.Stack(
  config.contentStack.apiKey,
  config.contentStack.deliveryToken,
  config.contentStack.environment,
);

export const getContent =
  (contentType, references = [], keys = []) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const contentState = getState().contentState;

      if (
        contentType in contentState &&
        contentState[contentType].data !== null &&
        contentState[contentType].loadedAt instanceof Date
      ) {
        const now = new Date();
        const timeSinceContentLoaded = now - contentState[contentType].loadedAt;
        if (timeSinceContentLoaded < CONTENT_TIMEOUT) {
          if (
            contentState[contentType].data.hero &&
            contentState.current_hero !==
              contentState[contentType].data.hero.image.url
          ) {
            dispatch(
              setHero({
                image: contentState[contentType].data.hero.image.url,
                short: contentState[contentType].data.hero.short_image.url,
              }),
            );
          }
          return resolve(contentState[contentType].data);
        }
      }

      dispatch(contentLoading(contentType));

      let query = Stack.ContentType(contentType).Query();

      if (references) {
        query = query.includeReference(references);
      }

      query
        .toJSON()
        .find()
        .then(
          (result) => {
            // restructure data to be flatter and reference key based
            const data = Object.assign(
              {},
              ...references.map((ref) => {
                return {
                  [ref]: result[0][0][ref][0],
                };
              }),
              ...keys.map((key) => {
                return {
                  [key]: result[0][0][key],
                };
              }),
            );

            if (
              data.hero &&
              contentState.current_hero !== data.hero.image.url
            ) {
              dispatch(
                setHero({
                  image: data.hero.image.url,
                  short: data.hero.short_image.url,
                }),
              );
            }

            dispatch(contentLoaded(contentType, data));

            resolve(result);
          },
          (error) => {
            reject(error);
          },
        );
    });
  };
