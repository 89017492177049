import React from 'react';
import { Log, OidcClient } from 'oidc-client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getContent } from '../../modules/Content';
import SSOLoginButton from '../../components/molecule/SSOLoginButton';

import config from '../../core/config';

import {
  Content,
  HeroPageHeading,
  HeroPageSubheading,
  LoadingContainer,
} from './styles.js';

class Trainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      width: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const { getContent } = this.props;

    getContent('landing_page', ['hero', 'landing_page_content']);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  renderPageHeading() {
    // todo: pull in from content stack...
    return (
      <img src="https://images.contentstack.io/v3/assets/blt4e037e928ac0f8eb/bltdddf5c1ef920f660/60fc3a8df46ba27d21795a45/Frame_264.png" />
    );
  }

  async login() {
    Log.logger = console;
    Log.level = Log.INFO;

    const settings = {
      authority: `${config.sso.url}/api/oidc`,
      client_id: config.sso.clientId,
      loadUserInfo: false,
      redirect_uri: config.sso.redirectUri,
      silent_redirect_uri: config.sso.redirectUri,
      response_type: 'code',
      response_mode: 'query',
      scope: 'openid email profile offline_access',
      prompt: 'consent',
      post_logout_redirect_uri: config.base_href,
    };

    const oidcClient = new OidcClient(settings);
    const signinRequest = await oidcClient.createSigninRequest();
    window.location.href = signinRequest.url;
  }

  render() {
    const {
      content: {
        landing_page: { data, isLoading },
      },
    } = this.props;

    return isLoading || data == null ? (
      <LoadingContainer>
        <CircularProgress size={70} style={{ color: '#caa854' }} />
      </LoadingContainer>
    ) : (
      <Content>
        <HeroPageHeading>{this.renderPageHeading()}</HeroPageHeading>
        <HeroPageSubheading>
          We&apos;ve teamed up with The St. James to bring the best digital
          training, wellness and sports content right to you wherever you are.
        </HeroPageSubheading>
        <SSOLoginButton />
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authState,
    content: state.contentState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent,
    },
    dispatch,
  );
}

Trainer.propTypes = {
  content: PropTypes.object,
  getContent: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Trainer);
