import * as ACTION from './constants';

const initialState = {
  csrftoken: undefined,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.SECURITY_BASE:
      return { ...initialState };
    case ACTION.SECURITY_CSRF_TOKEN_SET:
      return { ...state, csrftoken: action.csrftoken };
    default:
      return state;
  }
}
