import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const HeroPageSubheading = styled.div`
  color: #747679;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const LinkStyled = styled.a`
  color: #00253f;
  cursor: pointer;
  font-family: 'Volte Semibold';
  font-weight: 500;
  text-decoration: none;

  &:visited {
    color: #00253f;
  }
`;
