import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/styles';
import { SUBSCRIPTIONS } from '../../../core/constants';

import {
  ButtonText,
  Container,
  Cost,
  Period,
  PlanButtonContainer,
  PlanButton,
  Popular,
  styles,
} from './styles.js';

const planText = {
  [SUBSCRIPTIONS.annual.name]: {
    period: 'Yearly',
    cost: (price) => {
      return price;
    },
    term: 'year',
    subtext: (price) => {
      return `Billed $${price} every year`;
    },
    popular: 'Most Popular',
  },
  [SUBSCRIPTIONS.quarterly.name]: {
    period: 'Quarterly',
    cost: (price) => {
      return price;
    },
    term: 'quarter',
    subtext: () => {
      return 'Billed every 3 months';
    },
  },
  [SUBSCRIPTIONS.monthly.name]: {
    period: 'Monthly',
    cost: (price) => {
      return price;
    },
    term: 'month',
    subtext: () => {
      return 'Billed every month';
    },
  },
};

class PlanButtons extends React.Component {
  selectPlan = (planIndex) => {
    const { onSelect } = this.props;
    onSelect(planIndex);
  };

  renderCard = (plan) => {
    const { selectedPlan, classes, prices } = this.props;

    const price =
      prices && prices[plan] ? prices[plan] : SUBSCRIPTIONS[plan].totalPrice;

    let promo;
    if (plan === 'annual') {
      if (prices && prices.monthly && prices.annual) {
        promo = prices.monthly * 12 - prices.annual;
      } else {
        promo =
          SUBSCRIPTIONS.monthly.totalPrice * 12 -
          SUBSCRIPTIONS.annual.totalPrice;
      }
    }

    const buttonClasses =
      selectedPlan === plan ? [classes.selectedPlan] : [classes.unselectedPlan];

    return (
      <PlanButtonContainer key={plan}>
        <PlanButton
          className={classNames(buttonClasses)}
          onClick={() => this.selectPlan(plan)}
          style={
            planText[plan].popular ? { borderRadius: '4px 4px 0px 0px' } : {}
          }
        >
          <ButtonText>
            <Cost>
              {`$${planText[plan].cost(price)}`}/{planText[plan].term}
            </Cost>
            <Period style={selectedPlan === plan ? { color: '#6d502e' } : {}}>
              Billed {planText[plan].period}
            </Period>
          </ButtonText>
        </PlanButton>
        {planText[plan].popular && (
          <Popular
            className={selectedPlan === plan ? [classes.popularSelected] : []}
          >
            Most Popular | ${promo} in Savings
          </Popular>
        )}
      </PlanButtonContainer>
    );
  };

  render() {
    const { order } = this.props;

    return <Container>{order.map((item) => this.renderCard(item))}</Container>;
  }
}

PlanButtons.propTypes = {
  classes: PropTypes.shape({
    blur: PropTypes.string,
    focus: PropTypes.string,
    popularSelected: PropTypes.string,
    selectedPlan: PropTypes.string,
    unselectedPlan: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  order: PropTypes.arrayOf(PropTypes.string),
  prices: PropTypes.shape({
    annual: PropTypes.number,
    monthly: PropTypes.number,
    quarterly: PropTypes.number,
  }),
  selectedPlan: PropTypes.string,
};

export default withStyles(styles)(PlanButtons);
