import * as ACTION from './constants';

const initialState = {
  isFetching: false,
  isUpdating: false,
  customer: null,
  error: null,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.CUSTOMER_BASE:
      return { ...initialState };
    case ACTION.GET_CUSTOMER:
      return { ...state, isFetching: true, customer: null };
    case ACTION.SET_CUSTOMER:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        customer: {
          ...action.payload,
        },
        error: null,
      };
    case ACTION.CUSTOMER_ERROR:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        customer: null,
        error: action.error,
      };
    case ACTION.UPDATING:
      return { ...state, isUpdating: true };
    default:
      return state;
  }
}
