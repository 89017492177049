import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Modal from 'simple-react-modal';

import * as dayjs from 'dayjs';
import { StripeProvider, Elements, injectStripe } from 'react-stripe-elements';

import { CircularProgress } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  cancelSubscription,
  getSubscription,
  subscribe,
} from '../../modules/Subscription';
import {
  getCustomer,
  updateCustomerPaymentMethod,
} from '../../modules/Customer';

import logo from '../../assets/images/strivers_logo_black.png';
import config from '../../core/config';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ButtonStyled,
  CancelButton,
  CancelSubscriptionLink,
  CardBodyElementStyled,
  CardElementStyled,
  CardElementWrapper,
  ContactUsStyled,
  Container,
  ContentWrapper,
  DescriptionIconStyled,
  DisabledUpdateButton,
  EditFieldLink,
  ErrorMessageContainer,
  FieldWrapper,
  FieldRow,
  FieldLabel,
  FieldValue,
  FieldValueWrapper,
  Header,
  LinksWrapper,
  LinkStyled,
  LogoutIconStyled,
  LockIconStyled,
  MailIconStyled,
  ModalContentContainer,
  ModalTitle,
  ModalSubtitle,
  ModalButtonContainer,
  ModalKeepButton,
  ModalCancelButton,
  ProgressContainer,
  Spacer,
  SettingsContainer,
  StartNewSubscriptionLink,
  Title,
  TransparentLoading,
  UpdateButton,
  UpdateButtonContainer,
} from './styles.js';

const subscriptionStatusMap = {
  active: 'Active',
  trialing: 'Trial Period',
  canceled: 'Canceled',
  incomplete: 'Incomplete',
  incomplete_expired: 'Incomplete (Expired)',
  past_due: 'Past Due',
  unknown: 'Unknown',
};

const noop = () => {};

class _PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      complete: false,
      errorMessage: null,
    };
  }

  onCardElementChange = (changeObject) => {
    const { complete, error } = changeObject;

    const state = {
      errorMessage: error ? error.message : null,
      complete,
    };

    this.setState({ ...state });
  };

  async submit() {
    const {
      handleComplete,
      handlePaymentError,
      settings,
      stripe,
      updateCustomerPaymentMethod,
      validatingPaymentMethod,
    } = this.props;

    validatingPaymentMethod();

    // could be token or error
    const result = await stripe.createToken({ name: settings.name });

    if (result.token) {
      try {
        await updateCustomerPaymentMethod({
          token: result.token.id,
        });
        handleComplete();
      } catch (e) {
        this.setState({
          errorMessage: e.response.data.message,
        });
        handlePaymentError();
      }
    } else if (result.error) {
      this.setState({
        errorMessage: result.error.message,
      });
      handlePaymentError();
    } else {
      this.setState({
        errorMessage: 'Something went wrong. Try again.',
      });
      handlePaymentError();
    }
  }

  render() {
    //const { complete, errorMessage, isValidating } = this.state;
    const { complete, errorMessage } = this.state;
    const { handleCancel } = this.props;

    return (
      <CardElementWrapper>
        <CardElementStyled>
          <CardBodyElementStyled
            onChange={this.onCardElementChange}
            errorMessage={errorMessage}
          />
          {errorMessage && (
            <ErrorMessageContainer>{errorMessage}</ErrorMessageContainer>
          )}
          <UpdateButtonContainer>
            {!complete ? (
              <DisabledUpdateButton disabled={true}>
                Update Payment Method
              </DisabledUpdateButton>
            ) : (
              <UpdateButton onClick={this.submit}>
                Update Payment Method
              </UpdateButton>
            )}
            <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          </UpdateButtonContainer>
        </CardElementStyled>
      </CardElementWrapper>
    );
  }
}

_PaymentForm.propTypes = {
  handleCancel: PropTypes.func,
  handleComplete: PropTypes.func,
  handlePaymentError: PropTypes.func,
  settings: PropTypes.object,
  stripe: Elements.StripeProps,
  updateCustomerPaymentMethod: PropTypes.func,
  validatingPaymentMethod: PropTypes.func,
};

const PaymentForm = injectStripe(_PaymentForm);

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelModalVisible: false,
      editingFields: {},
      fieldErrors: {},
      submitting: false,
    };
  }

  async componentDidMount() {
    const { getSubscription, getCustomer } = this.props;

    try {
      await getSubscription();
      await getCustomer();
    } catch (e) {
      noop(e);
    }
  }

  editValue = (field) => {
    this.setState((prevState) => ({
      editingFields: {
        ...prevState.editingFields,
        [field]: true,
      },
    }));
  };

  showCancelModal = () => {
    this.setState({ cancelModalVisible: true });
  };
  hideCancelModal = () => {
    this.setState({ cancelModalVisible: false });
  };

  cancelSubscription = async () => {
    const {
      cancelSubscription,
      subscriptionState: { subscription },
    } = this.props;

    this.setState({ submitting: true });

    try {
      await cancelSubscription({ id: subscription.data.id });
    } catch (e) {
      console.log(e);
    }

    this.setState({
      cancelModalVisible: false,
      submitting: false,
    });
  };

  startNewSubscription = () => {
    alert('Not ready yet.');
  };

  handlePaymentMethodUpdated = () => {
    this.setState((prevState) => ({
      editingFields: {
        ...prevState.editingFields,
        paymentMethod: false,
      },
      fieldErrors: {
        ...prevState.fieldErrors,
        paymentMethod: null,
      },
      submitting: false,
    }));
  };

  handleCancelPaymentMethod = () => {
    this.setState((prevState) => ({
      editingFields: {
        ...prevState.editingFields,
        paymentMethod: false,
      },
    }));
  };

  validatingPaymentMethod = () => {
    this.setState((prevState) => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        paymentMethod: null,
      },
      submitting: true,
    }));
  };

  handlePaymentError = (errorMessage) => {
    this.setState((prevState) => ({
      fieldErrors: {
        ...prevState.fieldErrors,
        paymentMethod: errorMessage,
      },
      submitting: false,
    }));
  };

  render() {
    const { cancelModalVisible, editingFields, submitting } = this.state;
    const {
      customerState,
      handleLogout,
      settingsState,
      subscriptionState,
      updateCustomerPaymentMethod,
    } = this.props;

    const { customer } = customerState;
    const { isFetching, subscription } = subscriptionState;

    const status = subscription ? subscription.data.status : 'unknown';
    const subscriptionStatus = subscriptionStatusMap[status] || status;

    const cardBrand = customer ? customer.data.sources.data[0].brand : '';
    const cardLast4 = customer ? customer.data.sources.data[0].last4 : '';
    const cardExpMonth = customer
      ? customer.data.sources.data[0].exp_month
      : '';
    const cardExpYear = customer ? customer.data.sources.data[0].exp_year : '';

    const nextBillingDate = subscription
      ? dayjs.unix(subscription.data.current_period_end).format('MMMM DD, YYYY')
      : null;

    const canceledOnDate =
      subscription && status === 'canceled'
        ? dayjs.unix(subscription.data.canceled_at).format('MMMM DD, YYYY')
        : null;

    const { isFetching: isFetchingCustomer } = customerState;

    return isFetching || isFetchingCustomer ? (
      <ProgressContainer>
        <CircularProgress size={70} />
      </ProgressContainer>
    ) : (
      <>
        <Container>
          <Header>
            <Link to="/">
              <img width={150} src={logo} alt="Strivers x The St. James" />
            </Link>
            <ButtonStyled onClick={handleLogout}>
              <LogoutIconStyled /> Log Out
            </ButtonStyled>
          </Header>
          <Title>Account Management</Title>
          <SettingsContainer>
            <ContentWrapper>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  My Profile
                </AccordionSummary>
                <AccordionDetails>
                  <FieldWrapper>
                    <FieldRow>
                      <FieldLabel>Name</FieldLabel>
                      <FieldValueWrapper>
                        <FieldValue>
                          {settingsState.accountData.name}
                        </FieldValue>
                      </FieldValueWrapper>
                    </FieldRow>
                    <FieldRow>
                      <FieldLabel>Email</FieldLabel>
                      <FieldValueWrapper>
                        <FieldValue>
                          {settingsState.accountData.email}
                        </FieldValue>
                      </FieldValueWrapper>
                    </FieldRow>
                    <FieldRow style={{ alignItems: 'flex-start' }}>
                      <FieldLabel>Password</FieldLabel>
                      <FieldValueWrapper>
                        <FieldValue>************</FieldValue>
                        <EditFieldLink
                          href={`${config.sso.url}/change-password?client_id=${config.sso.clientId}&redirect_uri=${window.location.href}`}
                        >
                          Edit
                        </EditFieldLink>
                      </FieldValueWrapper>
                    </FieldRow>
                  </FieldWrapper>
                </AccordionDetails>
              </Accordion>
              {subscription && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    Manage Subscription
                  </AccordionSummary>
                  <AccordionDetails>
                    <FieldWrapper>
                      <FieldRow>
                        <FieldLabel>Status</FieldLabel>
                        <FieldValue>{subscriptionStatus}</FieldValue>
                        <Spacer />
                      </FieldRow>
                      {(status === 'active' ||
                        status === 'trialing' ||
                        status === 'incomplete' ||
                        status === 'past_due') && (
                        <>
                          {status !== 'incomplete' && status !== 'past_due' && (
                            <>
                              <FieldRow>
                                <FieldLabel>Next Billing Date</FieldLabel>
                                <FieldValue>{nextBillingDate}</FieldValue>
                                <Spacer />
                              </FieldRow>
                              <FieldRow>
                                <FieldLabel>Next Billing Amount</FieldLabel>
                                <FieldValue>
                                  ${subscription.data.plan.amount / 100}.00
                                </FieldValue>
                                <Spacer />
                              </FieldRow>
                            </>
                          )}
                          <FieldRow style={{ alignItems: 'flex-start' }}>
                            <FieldLabel>Payment Method</FieldLabel>
                            <FieldValue
                              style={{ alignItems: 'flex-start', padding: 0 }}
                            >
                              {cardBrand} **** {cardLast4}
                              <br />
                              Exp. {cardExpMonth}/{cardExpYear}
                            </FieldValue>
                            {editingFields.paymentMethod ? (
                              <Spacer />
                            ) : (
                              <EditFieldLink
                                onClick={() => this.editValue('paymentMethod')}
                              >
                                Update
                              </EditFieldLink>
                            )}
                          </FieldRow>
                        </>
                      )}
                      {(status === 'canceled' ||
                        status === 'incomplete_expired' ||
                        status === 'missing') && (
                        <>
                          {status === 'canceled' && (
                            <FieldRow>
                              <FieldLabel>Canceled On</FieldLabel>
                              <FieldValue>{canceledOnDate}</FieldValue>
                              <Spacer />
                            </FieldRow>
                          )}
                          <FieldRow>
                            <StartNewSubscriptionLink to="/subscription">
                              Start Subscription
                            </StartNewSubscriptionLink>
                          </FieldRow>
                        </>
                      )}
                      {editingFields.paymentMethod && (
                        <FieldRow>
                          <Spacer />
                          <StripeProvider apiKey={config.stripe.apiKey}>
                            <Elements>
                              <PaymentForm
                                handleCancel={this.handleCancelPaymentMethod}
                                handleComplete={this.handlePaymentMethodUpdated}
                                handlePaymentError={this.handlePaymentError}
                                updateCustomerPaymentMethod={
                                  updateCustomerPaymentMethod
                                }
                                settings={settingsState}
                                validatingPaymentMethod={
                                  this.validatingPaymentMethod
                                }
                              />
                            </Elements>
                          </StripeProvider>
                          {/*<EditFieldLink onClick={this.cancelPaymentMethod}>
                            Cancel
                          </EditFieldLink>*/}
                        </FieldRow>
                      )}
                      {(status === 'active' ||
                        status === 'trialing' ||
                        status === 'incomplete' ||
                        status === 'past_due') && (
                        <FieldRow>
                          <CancelSubscriptionLink
                            onClick={this.showCancelModal}
                          >
                            Cancel Subscription
                          </CancelSubscriptionLink>
                        </FieldRow>
                      )}
                    </FieldWrapper>
                  </AccordionDetails>
                </Accordion>
              )}
              {settingsState.accountData.trainer &&
                settingsState.accountData.trainer.name && (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Manage Program
                    </AccordionSummary>
                    <AccordionDetails>
                      <FieldWrapper>
                        <FieldRow>
                          <FieldLabel>Your Main Trainer</FieldLabel>
                          <FieldValue>
                            {settingsState.accountData.trainer &&
                              settingsState.accountData.trainer.name}
                          </FieldValue>
                        </FieldRow>
                      </FieldWrapper>
                    </AccordionDetails>
                  </Accordion>
                )}
            </ContentWrapper>
            <LinksWrapper>
              <ContactUsStyled href={`mailto:${config.contact.memberservices}`}>
                <MailIconStyled /> Contact Us
              </ContactUsStyled>
              <LinkStyled href="/privacy-policy" target="_blank">
                <LockIconStyled /> Privacy Policy
              </LinkStyled>
              <LinkStyled href="/terms-of-use" target="_blank">
                <DescriptionIconStyled /> Terms of Use
              </LinkStyled>
            </LinksWrapper>
          </SettingsContainer>
          <Modal
            containerStyle={{
              padding: '24px',
              maxWidth: '90%',
            }}
            onClose={this.hideCancelModal}
            show={cancelModalVisible}
            style={{
              background: 'rgba(0, 0, 0, 0.25)',
            }}
          >
            <ModalContentContainer>
              <ModalTitle>Cancel Subscription</ModalTitle>
              <ModalSubtitle>
                Are you sure you want to cancel your subscription?
              </ModalSubtitle>
              <ModalButtonContainer>
                <ModalKeepButton onClick={this.hideCancelModal}>
                  Keep Subscription
                </ModalKeepButton>
                <ModalCancelButton onClick={this.cancelSubscription}>
                  Cancel Subscription
                </ModalCancelButton>
              </ModalButtonContainer>
            </ModalContentContainer>
          </Modal>
        </Container>
        {submitting && (
          <TransparentLoading>
            <CircularProgress size={70} style={{ color: '#caa854' }} />
          </TransparentLoading>
        )}
      </>
    );
  }
}

Settings.propTypes = {
  cancelSubscription: PropTypes.func,
  customerState: PropTypes.object,
  getCustomer: PropTypes.func,
  getSubscription: PropTypes.func,
  handleLogout: PropTypes.func,
  settingsState: PropTypes.object,
  subscribe: PropTypes.func,
  subscriptionState: PropTypes.object,
  trainerState: PropTypes.object,
  updateCustomerPaymentMethod: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    customerState: state.customerState,
    subscriptionState: state.subscriptionState,
    settingsState: state.settingsState,
    trainerState: state.trainerState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      cancelSubscription,
      getCustomer,
      getSubscription,
      subscribe,
      updateCustomerPaymentMethod,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
