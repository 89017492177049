import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Container } from './styles.js';

class LoadingScreen extends React.Component {
  render() {
    return (
      <Container>
        <CircularProgress
          size={80}
          thickness={4}
          style={{ color: '#caa854' }}
        />
      </Container>
    );
  }
}

export default LoadingScreen;
