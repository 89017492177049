import React from 'react';
import { Log, OidcClient } from 'oidc-client';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';

import { Redirect, withRouter } from 'react-router-dom';
import { withCookies, Cookies } from 'react-cookie';

import { CircularProgress } from '@material-ui/core';

import { exchangeCode } from '../../modules/Auth';
import { setCsrfToken } from '../../modules/Security';

import config from '../../core/config';

import {
  ContactUsLink,
  Content,
  HeroPageHeading,
  HeroPageSubheading,
  LoadingContainer,
  MiniDivider,
  NeedHelp,
} from './styles.js';

class OIDC extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      invalidRequest: false,
      isAuthenticating: true,
      redirectTo: null,
      requestError: false,
    };
  }

  async componentDidMount() {
    const {
      cookies,
      exchangeCode,
      security: { csrftoken },
      setCsrfToken,
      trainer: { trainer },
    } = this.props;

    Log.logger = console;
    Log.level = Log.INFO;

    const oidcClient = new OidcClient({
      authority: `${config.sso.url}/api/oidc`,
      client_id: config.sso.clientId,
      loadUserInfo: false,
      redirect_uri: config.sso.redirectUri,
      silent_redirect_uri: config.sso.redirectUri,
      response_type: 'code',
      response_mode: 'query',
      scope: 'openid email profile offline_access',
      prompt: 'consent',
      post_logout_redirect_uri: config.base_href,
    });

    const { response, state } = await oidcClient.readSigninResponseState();

    let invalidRequest = false;
    let requestError = false;
    let errorMessage;

    if (response.code) {
      try {
        await exchangeCode(response.code, state._code_verifier, trainer);
      } catch (e) {
        requestError = true;
        errorMessage = e.message ? e.message : e;
      }
    } else {
      invalidRequest = true;
    }

    const csrftoken_cookie = cookies.get(config.api.csrfCookieName);

    // if no token in state and there is a cookie, reset from cookie
    if (csrftoken_cookie && csrftoken_cookie !== csrftoken) {
      setCsrfToken(csrftoken_cookie);
    }

    this.setState({
      errorMessage,
      invalidRequest,
      requestError,
    });
  }

  componentDidUpdate(prevProps) {
    let redirectTo = null;

    if (
      prevProps.settings.accountData == null &&
      this.props.settings.accountData != null
    ) {
      if (this.props.settings.accountData.premium_access) {
        redirectTo = '/settings';
      } else {
        redirectTo = '/subscription';
      }

      this.setState({ isAuthenticating: false, redirectTo });
    }
  }

  render() {
    const {
      errorMessage,
      invalidRequest,
      isAuthenticating,
      redirectTo,
      requestError,
    } = this.state;

    return isAuthenticating ? (
      <LoadingContainer>
        <CircularProgress size={70} style={{ color: '#caa854' }} />
      </LoadingContainer>
    ) : redirectTo ? (
      <Redirect to={redirectTo} />
    ) : (
      <>
        <Content>
          {invalidRequest || requestError ? (
            <>
              <HeroPageHeading>Confirmation error</HeroPageHeading>
              <HeroPageSubheading>
                Something happened. Please try again.
              </HeroPageSubheading>
            </>
          ) : (
            <>
              <HeroPageHeading>Confirmation error</HeroPageHeading>
              <HeroPageSubheading>{errorMessage}</HeroPageSubheading>
            </>
          )}
          <MiniDivider />
          <NeedHelp>
            Need help? Contact Us:{' '}
            <ContactUsLink href={`mailto:${config.contact.memberservices}`}>
              {config.contact.memberservices}
            </ContactUsLink>
          </NeedHelp>
        </Content>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    security: state.securityState,
    setCsrfToken: setCsrfToken,
    settings: state.settingsState,
    trainer: state.trainerState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      exchangeCode: exchangeCode,
    },
    dispatch,
  );
}

OIDC.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  exchangeCode: PropTypes.func,
  security: PropTypes.shape({
    csrftoken: PropTypes.string,
  }),
  setCsrfToken: PropTypes.func,
  settings: PropTypes.object,
  trainer: PropTypes.object,
};

export default compose(
  withRouter,
  withCookies,
  connect(mapStateToProps, mapDispatchToProps),
)(OIDC);
