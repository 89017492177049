import * as ACTION from './constants';
import * as apiService from '../../services/apiServices';

export const isFetchingUserSettings = () => ({ type: ACTION.GET_USER_DATA });
export const setUserSettings = (payload = null) => ({
  type: ACTION.SET_USER_DATA,
  payload,
});

export const getAccountSettings = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(isFetchingUserSettings());
    apiService
      .getAccountSettings()
      .then((response) => {
        dispatch(setUserSettings(response));
        resolve(response);
      })
      .catch((error) => {
        dispatch(setUserSettings());
        reject(error);
      });
  });
};
