import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(400px, 100vh);
  position: relative;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;

    ${({ pathname }) =>
      pathname !== '/' &&
      `
        grid-template-rows: auto;
      `}
  }
`;

export const HeroColumn = styled.div``;

export const HeroImage = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const LogoImage = styled.img`
  position: absolute;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  right: 0;
  text-align: center;
  top: 60px;
`;

export const HeroContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  @media (max-width: 800px) {
    ${({ pathname }) =>
      pathname === '/' &&
      `
        max-height: 90vh;
        position: absolute;
        top: 30vh;
      `}
    width: 100%;
  }
`;

export const HeroContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 800px) {
    flex-grow: 0;
  }
`;

export const HeroContentInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 456px;
  position: relative;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const FooterContainer = styled.div`
  bottom: 0;
  left: 50%;
  position: absolute;
  width: 50%;

  @media (max-width: 800px) {
    bottom: auto;
    left: auto;
    margin-top: 48px;
    position: relative;
    width: 100%;
  }
`;

export const FooterInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const CuriousContainer = styled.div`
  color: #1e1f20;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  @media (max-width: 800px) {
    margin: 16px 0;
  }
`;

export const FAQsLink = styled(Link)`
  color: #1e1f20;
  font-weight: 600;
`;

export const TermsLink = styled(Link)`
  color: #1e1f20;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
`;
