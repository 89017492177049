import { ASSET_HOST } from './constants';

export const data = {
  fallback_trainer: {
    channel_slug: 'jojopolk',
  },
  aliases: {
    'jojo-polk': 'jojopolk',
    'angie-hall': 'angiehall',
    'ashley-farrar': 'ashleyfarrar',
    'bambi-willow': 'bambiwillow',
    'brandon-parker': 'brandonparker',
    'cameron-barbour': 'cameronbarbour1',
    'conner-traywick': 'connertraywick',
    'crystal-davis': 'crystaldavis',
    'crystal-seaver': 'crystalseaver',
    'danielle-maltby': 'dmmaltby',
    'janassa-kearney': 'fitnesswithnassa',
    'chrys-crockett': 'getfitwithchrys',
    'hannah-beth': 'hannahbeth',
    'jen-dufresne': 'jenwdufresne',
    'josh-rogers': 'joshrogers',
    'bre-leach': 'justbnfit',
    'katie-collard': 'katiecollard',
    'lauren-cederstrom': 'laurcederstrom',
    'leah-ward': 'leahmward',
    'leigh-ann-yeager': 'leighannyeager',
    'lily-saito': 'lilysaito',
    'logan-aldridge': 'loganaldridge',
    'lynn-fernandez': 'lynnfernandez',
    'marie-belle': 'mariebellepr',
    'ryan-mintz': 'mintzflow',
    'matt-thomas': 'movingwithmatt',
    'nelson-cuadras': 'nelsoncuadras',
    'noell-yanik': 'noellyanikfitness',
    'paige-fuson': 'paigefuson',
    rocsi: 'rocsiglobalfitness',
    'hilton-alexander': 'routeking_1',
    steph: 'stephthehammer',
    'steph-hammerman': 'stephthehammer',
    hammerdrivenfitness: 'stephthehammer',
    'tate-lea': 'tanasialea',
    'winter-holbrook': 'winterholbrook',
    eazy: 'yogaplayground',
    'bambi-watt': 'bambiwillow',
    'route-king': 'routeking_1',
    'lidia-ortega': 'lidiaortega',
    'jeff-toney': 'jefftoney',
    'titus-unlimited': 'titusunlimited',
    barmalik: 'mubarakmalik',
    'bar-malik': 'mubarakmalik',
    'dynasti-hunt': 'dynastihunt',
    'lynneise-karadsheh': 'karadshehfit',
    'lauren-arps': 'laurenarps',
    'brooklyn-sittner': 'queen_beezy13' /* typo */,
    'brooklynn-sittner': 'queen_beezy13',
    'meghan-lalor': 'meghanlalor',
    thichapilates: 'thicha',
    davidhiggins: 'davidhigginslondon',
    'david-higgins': 'davidhigginslondon',
    'lisa-kaye': 'lisakayepilates',
    'lisa-kaye-pilates': 'lisakayepilates',
    'lisa-hubbard': 'lisahubbardpilates',
    'lisa-hubbard-pilates': 'lisahubbardpilates',
    'lindsey-marie-fit': 'lindseymariefit',
    sarahmanns: 'thefitnesslioness',
    'sarah-manns': 'thefitnesslioness',
    'natalie-bally': 'sweatforthesoul',
    nataliebally: 'sweatforthesoul',
  },
  sites: {
    'david-harbour': {
      image:
        'https://d3sivlfumj4n3y.cloudfront.net/davidhiggins_davidharbour.jpg',
      name: 'David Harbour',
      trainer_slug: 'davidhigginslondon',
    },
    'david-higgins': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/davidhiggins_kumail.jpg',
      name: 'David Higgins',
      trainer_slug: 'davidhigginslondon',
    },
    davidhigginslondon: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/davidhiggins_kumail.jpg',
      name: 'David Higgins',
      trainer_slug: 'davidhigginslondon',
    },
    'ed-skrein': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/davidhiggins_edskrein.jpg',
      name: 'Ed Skrein',
      trainer_slug: 'davidhigginslondon',
    },
    'jodie-comer': {
      image:
        'https://d3sivlfumj4n3y.cloudfront.net/davidhiggins_jodiecomer.jpg',
      name: 'Jodie Comer',
      trainer_slug: 'davidhigginslondon',
    },
    'kumail-nanjiani': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/davidhiggins_kumail.jpg',
      name: 'Kumail Nanjiani',
      trainer_slug: 'davidhigginslondon',
    },
    'richard-madden': {
      image:
        'https://d3sivlfumj4n3y.cloudfront.net/davidhiggins_richardmadden.jpg',
      name: 'Richard Madden',
      trainer_slug: 'davidhigginslondon',
    },
    'samuel-l-jackson': {
      image:
        'https://d3sivlfumj4n3y.cloudfront.net/davidhiggins_samuelljackson.jpg',
      name: 'Samuel L Jackson',
      trainer_slug: 'davidhigginslondon',
    },
    'phil-fit': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/philfit_tylercameron.jpg',
      name: 'Phil Fit',
      trainer_slug: 'philfit',
    },
    philfit: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/philfit_tylercameron.jpg',
      name: 'Phil Fit',
      trainer_slug: 'philfit',
    },
    'tyler-cameron': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/philfit_tylercameron.jpg',
      name: 'Tyler Cameron',
      trainer_slug: 'philfit',
    },
    'sarah-manns': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/thefitnesslioness.jpg',
      name: 'Fitness Lioness',
      trainer_slug: 'thefitnesslioness',
    },
    thefitnesslioness: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/thefitnesslioness.jpg',
      name: 'Fitness Lioness',
      trainer_slug: 'thefitnesslioness',
    },
    'olivia-poling': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/olivia-poling.jpg',
      name: 'Olivia Poling',
      trainer_slug: 'olivia-poling',
    },
    oliviapoling: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/olivia-poling.jpg',
      name: 'Olivia Poling',
      trainer_slug: 'olivia-poling',
    },
    'lindsey-marie-fit': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/lindseymariefit.jpg',
      name: 'Lindsey Greeley',
      trainer_slug: 'lindseymariefit',
    },
    lindseymariefit: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/lindseymariefit.jpg',
      name: 'Lindsey Greeley',
      trainer_slug: 'lindseymariefit',
    },
    sweatforthesoul: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/sweatforthesoul.jpg',
      name: 'Sweat for the Soul',
      trainer_slug: 'sweatforthesoul',
    },
    'natalie-bally': {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/sweatforthesoul.jpg',
      name: 'Sweat for the Soul',
      trainer_slug: 'sweatforthesoul',
    },
    dillonvibes: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/dillonvibes.jpg',
      name: 'Vibes Training',
      trainer_slug: 'dillonvibes',
    },
    tcrosleyfit: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/thedailyglow.jpg',
      name: 'The Daily Glow',
      trainer_slug: 'thedailyglow',
    },
    thedailyglow: {
      image: 'https://d3sivlfumj4n3y.cloudfront.net/thedailyglow.jpg',
      name: 'The Daily Glow',
      trainer_slug: 'thedailyglow',
    },
    angiehall: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/98f2ffe2-5e98-4a29-a67f-ed451b1e1bb6',
      name: 'Angie Hall',
      trainer_slug: 'angiehall',
    },
    jojopolk: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/977c120b-0ee2-4d2f-a3c1-0be41b45138f',
      name: 'JoJo Polk',
      trainer_slug: 'jojopolk',
    },
    joshrogers: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/1f66ddaf-cb77-47a8-a043-3aaf120f09af',
      name: 'Josh Rogers',
      trainer_slug: 'joshrogers',
    },
    routeking_1: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/2d3a11df-148d-4bbd-b5ed-8b18128f8559',
      name: 'Hilton Alexander',
      trainer_slug: 'routeking_1',
    },
    stephthehammer: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/71fb3ec0-4f13-49d8-869a-0040429d00ae',
      name: 'Steph',
      trainer_slug: 'stephthehammer',
    },
    lynnfernandez: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/c45b3466-be7f-4a52-8586-f4f9e9371374',
      name: 'Lynn Fernandez',
      trainer_slug: 'lynnfernandez',
    },
    lilysaito: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/2cb9f870-5a75-447c-837f-e56247b929bb',
      name: 'Lily Saito',
      trainer_slug: 'lilysaito',
    },
    noellyanikfitness: {
      image:
        'https://s3.amazonaws.com/bnafit-image/b50f5f1c-36be-4c8c-ab99-e754107bdc1d',
      name: 'Noell Yanik',
      trainer_slug: 'noellyanikfitness',
    },
    paigefuson: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/0a266aa0-5c5c-4ed0-b1c6-1006411e94bb',
      name: 'Paige Fuson',
      trainer_slug: 'paigefuson',
    },
    justbnfit: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/8efd9d34-2007-480a-b10a-0ea5ab431f26',
      name: 'Bre Leach',
      trainer_slug: 'justbnfit',
    },
    brandonparker: {
      image:
        'https://s3.amazonaws.com/bnafit-image/558ec5f6-7fb8-422a-a160-d6f5ce736681',
      name: 'Brandon Parker',
      trainer_slug: 'brandonparker',
    },
    cameronbarbour1: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/9140d13b-977c-43d0-aae3-b5ddfc9b252b',
      name: 'Cameron Barbour',
      trainer_slug: 'cameronbarbour1',
    },
    crystaldavis: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/f5a736d3-3860-4da8-b12e-57e2ab954f93',
      name: 'Crystal Davis',
      trainer_slug: 'crystaldavis',
    },
    katiecollard: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/968ad71f-976f-4547-a01a-8e08ad7cb0c3',
      name: 'Katie Collard',
      trainer_slug: 'katiecollard',
    },
    yogaplayground: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/10a999a9-557c-42b9-a375-9c78b32dd234',
      name: 'Eazy',
      trainer_slug: 'yogaplayground',
    },
    hannahbeth: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/5c3727c6-b284-4b2f-9d59-4f43d4355938',
      name: 'Hannah Beth Millman',
      trainer_slug: 'hannahbeth',
    },
    movingwithmatt: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/53a69aea-3c63-410c-b625-35f21391508b',
      name: 'Matt Thomas',
      trainer_slug: 'movingwithmatt',
    },
    mariebellepr: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/3cbff8e7-bd64-4993-b4d8-fa9286467b00',
      name: 'Marie Belle Perez Rivera',
      trainer_slug: 'mariebellepr',
    },
    raven: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/ade729d3-0cdb-42e3-8a16-934b3a2d45b4',
      name: 'Raven',
      trainer_slug: 'raven',
    },
    leighannyeager: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/405cb441-8f37-4191-b9b5-28407e40f376',
      name: 'Leigh Ann Yeager',
      trainer_slug: 'leighannyeager',
    },
    fitnesswithnassa: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/250e8bca-acf4-4adf-8b8d-9fc96fc04787',
      name: 'Janassa Kearney',
      trainer_slug: 'fitnesswithnassa',
    },
    leahmward: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/fd4237cc-a745-46ec-be40-4beeb1dc797d',
      name: 'Leah Ward',
      trainer_slug: 'leahmward',
    },
    ashleyfarrar: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/60151be0-0f02-4071-b972-783462532486',
      name: 'Ashley Farrar',
      trainer_slug: 'ashleyfarrar',
    },
    bambiwillow: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/ad7b258f-56be-4d66-bb9d-945bfa41535f',
      name: 'Bambi Watt',
      trainer_slug: 'bambiwillow',
    },
    connertraywick: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/1ce5790a-ba00-48c8-9de1-f1c16a6d172e',
      name: 'Conner Traywick',
      trainer_slug: 'connertraywick',
    },
    crystalseaver: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/8800242d-9a2b-4e2e-98ef-dcaf2e3a920d',
      name: 'Crystal Seaver',
      trainer_slug: 'crystalseaver',
    },
    getfitwithchrys: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/41a7a29e-f527-4010-9771-cdb80754e0a8',
      name: 'Chrys Crockett',
      trainer_slug: 'getfitwithchrys',
    },
    jenwdufresne: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/985a60ab-5b4b-488e-8dca-cac2e5a39e4d',
      name: 'Jen Dufresne',
      trainer_slug: 'jenwdufresne',
    },
    laurcederstrom: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/a2b785e5-79d1-46b8-b082-106225f6a3eb',
      name: 'Lauren Cederstrom',
      trainer_slug: 'laurcederstrom',
    },
    loganaldridge: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/102f0148-3cef-4fcc-881d-d5781ab71f34',
      name: 'Logan Aldridge',
      trainer_slug: 'loganaldridge',
    },
    mintzflow: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/2b019563-53dd-4279-911c-cbbe54658e93',
      name: 'Ryan Mintz',
      trainer_slug: 'mintzflow',
    },
    nelsoncuadras: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/9e2c6f99-e40b-4bd0-889f-accc5425a0e4',
      name: 'Nelson Cuadras',
      trainer_slug: 'nelsoncuadras',
    },
    rocsiglobalfitness: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/cc9697c4-abd4-4007-9ed2-5691ec0e6506',
      name: 'Rocsi',
      trainer_slug: 'rocsiglobalfitness',
    },
    tanasialea: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/0869441b-ce8f-402e-ab30-ac83e0fd6a71',
      name: 'Tate Lea',
      trainer_slug: 'tanasialea',
    },
    winterholbrook: {
      image:
        'https://s3.amazonaws.com/bnafit-image/b7eba5f6-5140-4117-bc7d-7d8b4a200240',
      name: 'Winter Holbrook',
      trainer_slug: 'winterholbrook',
    },
    lidiaortega: {
      image:
        'https://s3.amazonaws.com/bnafit-image/8851f562-fc30-4b89-9784-18b8f30746ed',
      name: 'Lidia Ortega',
      trainer_slug: 'lidiaortega',
    },
    jefftoney: {
      image:
        'https://bnafit-image.s3.amazonaws.com/750b83e6-7240-48df-a44d-c31ec05d2610',
      name: 'Jeff Toney',
      trainer_slug: 'jefftoney',
    },
    titusunlimited: {
      image:
        'https://bnafit-image.s3.amazonaws.com/6d9772c0-5bd6-44e7-a6d0-3b7d84a10f71',
      name: 'Titus Unlimited',
      trainer_slug: 'titusunlimited',
    },
    mubarakmalik: {
      image:
        'https://bnafit-image.s3.amazonaws.com/d1729664-f523-46c2-b415-cc9a7c20230c',
      name: 'Bar Malik',
      trainer_slug: 'mubarakmalik',
    },
    dynastihunt: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/507429e0-09ad-493a-bced-07cd1be05c55',
      name: 'Dynasti Hunt',
      trainer_slug: 'dynastihunt',
    },
    karadshehfit: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/b884486b-9f4d-4831-a853-61e429323871',
      name: 'Lynneise Karadsheh',
      trainer_slug: 'karadshehfit',
    },
    laurenarps: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/0433be0b-5b2a-4dbd-bd5e-55e308977aa5',
      name: 'Lauren Arps',
      trainer_slug: 'laurenarps',
    },
    queen_beezy13: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/6e4640f0-9fbf-4cfe-b97a-67232f4812ed',
      name: 'Brooklynn Sittner',
      trainer_slug: 'queen_beezy13',
    },
    meghanlalor: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/df949946-637d-4bdf-ac87-76c14bf73a42',
      name: 'Meghan Lalor',
      trainer_slug: 'meghanlalor',
    },
    thichapilates: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/c7f50d7d-f384-4022-a601-163d33bc2185',
      name: 'Thicha',
      trainer_slug: 'thichapilates',
    },
    lisakayepilates: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/18d36d83-d7c7-476a-b5a8-28b102e41f26',
      name: 'Lisa Kaye',
      trainer_slug: 'lisakayepilates',
    },
    lisahubbardpilates: {
      image:
        'https://d4vdobyufbs6d.cloudfront.net/de4b6c0f-6350-4897-a850-3f86d96773cf',
      name: 'Lisa Hubbard',
      trainer_slug: 'lisahubbardpilates',
    },
  },
  angiehall: {
    user_id: 7,
    bio_backdrop: `${ASSET_HOST}/angiehall_bio.jpg`,
    bio_video: `${ASSET_HOST}/angiehall_bio_20200205T172028.m3u8`,
    class_image: `${ASSET_HOST}/angiehall.png`,
    class_video: `${ASSET_HOST}/angiehall.mp4`,
    name: 'Angie Hall',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/07b17127-3e11-4949-8684-7ea1ad1f01ad',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/dd9b7997-5f38-421c-a63b-4a06b13bcfcd.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/98f2ffe2-5e98-4a29-a67f-ed451b1e1bb6',
    trusted: [
      {
        title: 'Featured In',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/angiehall-delaware_today.png`,
        video: '',
      },
      {
        title: 'Ambassador',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/angiehall-teeki_logo.png`,
        video: '',
      },
      {
        title: 'Ambassador',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/angiehall-idosup.png`,
        video: '',
      },
      {
        title: 'Ambassador',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/angiehall-pelagic_gear_logo.png`,
        video: '',
      },
    ],
  },
  template: {
    user_id: '',
    bio_backdrop: '',
    bio_video: '',
    class_image: '',
    class_video: '',
    name: '',
    playbook_image: '',
    playbook_video: '',
    picture: '',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/`,
        video: '',
      },
    ],
  },
  jojopolk: {
    user_id: 5,
    bio_backdrop: `${ASSET_HOST}/jojopolk_bio.jpg`,
    bio_video: `${ASSET_HOST}/jojopolk_bio_waam.m3u8`,
    class_image: `${ASSET_HOST}/jojopolk.png`,
    class_video: `${ASSET_HOST}/jojopolk.mp4`,
    name: 'JoJo Polk',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/826f68fb-401b-48a2-b56c-f547ca81d46d',
    playbook_video: `${ASSET_HOST}/jojopolk_workout_20200206T130734.m3u8`,
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/977c120b-0ee2-4d2f-a3c1-0be41b45138f',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/jojopolk-mens_health.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/jojopolk-la_times.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/lululemon_logo.png`,
        video: '',
      },
      {
        title: 'Awarded Most Inspirational Player',
        caption: 'After a neck injury left him',
        caption_name: 'paralyzed from the waist down in 2000',
        image: `${ASSET_HOST}/jojopolk-tulsa_talons.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Won 2 American Indoor Football',
        caption_name: 'Association championships',
        image: `${ASSET_HOST}/jojopolk-indoor_football_league.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/jojopolk-wral_sports.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Voted Best Instructor',
        caption_name: '',
        image: `${ASSET_HOST}/jojopolk-classpass_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/jojopolk-walter_magazine.png`,
        video: '',
      },
    ],
  },
  joshrogers: {
    user_id: 41,
    bio_backdrop: `${ASSET_HOST}/joshrogers_bio.jpg`,
    bio_video: `${ASSET_HOST}/joshrogers_bio_20200205T213541.m3u8`,
    class_image: `${ASSET_HOST}/joshrogers.png`,
    class_video: `${ASSET_HOST}/joshrogers.mp4`,
    name: 'Josh Rogers',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/5f196f2f-7b6e-454c-a69f-c71ad886f495',
    playbook_video: `${ASSET_HOST}/joshrogers_workout_20200205T214210.m3u8`,
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/1f66ddaf-cb77-47a8-a043-3aaf120f09af',
    trusted: [
      {
        title: 'Trusted Trainer For',
        caption: 'Victoria Secret Model',
        caption_name: 'Sofie Rovenstine',
        image: `${ASSET_HOST}/joshrogers-sofie_rovenstine.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Miss Mississippi USA 2020',
        caption_name: 'Asya Branch',
        image: `${ASSET_HOST}/joshrogers-aysa_branch.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Miss Mississippi Teen USA 2020',
        caption_name: 'Zoe Bigham',
        image: `${ASSET_HOST}/joshrogers-zoe_bigham.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Political Historian/Author/TV Personality',
        caption_name: 'Jon Meacham',
        image: `${ASSET_HOST}/joshrogers-jon_meacham.png`,
        video: '',
      },
      {
        title: 'Voted',
        caption: 'Best Local Gym',
        caption_name: '4 consecutive times',
        image: `${ASSET_HOST}/joshrogers-nashville_scene.jpeg`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Miss for America 2019',
        caption_name: 'Kassie Perkins',
        image: `${ASSET_HOST}/joshrogers-kassie_perkins.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Miss Tennessee 2019',
        caption_name: 'Brianna Mason',
        image: `${ASSET_HOST}/joshrogers-brianna_mason.png`,
        video: '',
      },
      {
        title: 'Featured On',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/joshrogers-WSMV-TV_4_logo.png`,
        video: '',
      },
      {
        title: 'Worked with',
        caption: 'Olympic Gymnast',
        caption_name: 'Shawn Johnson',
        image: `${ASSET_HOST}/joshrogers-shawn_johnson.png`,
        video: '',
      },
      {
        title: 'Worked with',
        caption: 'TV Show Star',
        caption_name: 'Savannah Chrisley',
        image: `${ASSET_HOST}/joshrogers-savannah_chrisley.png`,
        video: '',
      },
      {
        title: 'Worked with',
        caption: 'Miss Pennsylvania USA 2018',
        caption_name: 'Kailyn Marie Perez',
        image: `${ASSET_HOST}/joshrogers-kailyn_marie_perez.png`,
        video: '',
      },
      {
        title: 'Worked with',
        caption: 'Miss USA 2007',
        caption_name: 'Rachel Smith',
        image: `${ASSET_HOST}/joshrogers-rachel_smith.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Lululemon',
        caption_name: '',
        image: `${ASSET_HOST}/lululemon_logo.png`,
        video: '',
      },
    ],
  },
  routeking_1: {
    user_id: 48,
    bio_backdrop: `${ASSET_HOST}/routeking_1_bio.jpg`,
    bio_video: `${ASSET_HOST}/routeking_1_bio_20200205T220658.m3u8`,
    class_image: `${ASSET_HOST}/routeking_1.png`,
    class_video: `${ASSET_HOST}/routeking_1.mp4`,
    name: 'Hilton Alexander',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/23e0116a-66ba-4381-962c-174ea54d8e64',
    playbook_video: `${ASSET_HOST}/routeking_1_workout_20200205T220220.m3u8`,
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/2d3a11df-148d-4bbd-b5ed-8b18128f8559',
    trusted: [
      {
        title: 'Trusted Trainer For',
        caption: 'New England Patriots Wide Receiver',
        caption_name: 'Jakobi Meyers',
        image: `${ASSET_HOST}/routeking_1-jakobi_meyers.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Las Vegas Raiders Tight End',
        caption_name: 'Darren Waller',
        image: `${ASSET_HOST}/routeking_1-darren_waller.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'New England Patriots Wide Receiver',
        caption_name: 'Mohamed Sanu Sr.',
        image: `${ASSET_HOST}/routeking_1-mohamed_sanu.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Atlanta Falcons Wide Receiver',
        caption_name: 'Justin Hardy',
        image: `${ASSET_HOST}/routeking_1-justin_hardy.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Atlanta Falcons Wide Receiver',
        caption_name: 'Calvin Ridley',
        image: `${ASSET_HOST}/routeking_1-calvin_ridley.png`,
        video: '',
      },
      {
        title: 'Worked with',
        caption: 'Los Angeles Chargers Quarterback',
        caption_name: 'Tyrod Taylor',
        image: `${ASSET_HOST}/routeking_1-tyrod_taylor.png`,
        video: '',
      },
    ],
  },
  stephthehammer: {
    user_id: 24,
    bio_backdrop: `${ASSET_HOST}/stephthehammer_bio.jpg`,
    bio_video: `${ASSET_HOST}/stephthehammer_bio_20200205T222520.m3u8`,
    class_image: `${ASSET_HOST}/stephthehammer.png`,
    class_video: `${ASSET_HOST}/stephthehammer.mp4`,
    name: 'Steph',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/284f0973-5803-449b-a3f7-afe04196b577',
    playbook_video: `${ASSET_HOST}/stephthehammer_workout_20200205T222542.m3u8`,
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/71fb3ec0-4f13-49d8-869a-0040429d00ae',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/stephthehammer-good_morning_america.png`,
        video: '',
      },
      {
        title: '',
        caption: 'First Crossfit Trainer',
        caption_name: 'with cerebral palsy',
        image: `${ASSET_HOST}/stephthehammer-crossfit.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Nike Sponsored Athlete',
        caption_name: '',
        image: `${ASSET_HOST}/stephthehammer-nike.png`,
        video: '',
      },
      {
        title: '',
        caption: '1st Place',
        caption_name: 'Spirit of the Games',
        image: `${ASSET_HOST}/stephthehammer-wodapalooza.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Owner of',
        caption_name: 'Hammer Driven Fitness',
        image: `${ASSET_HOST}/stephthehammer-hammer_driven_fitness.jpeg`,
        video: '',
      },
    ],
  },
  lynnfernandez: {
    user_id: 38,
    bio_backdrop: `${ASSET_HOST}/lynnfernandez_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/ffd7bd16-2713-4e17-8e56-9d1060661032.m3u8',
    class_image: `${ASSET_HOST}/lynnfernandez.png`,
    class_video: `${ASSET_HOST}/lynnfernandez.mp4`,
    name: 'Lynn Fernandez',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/7829a3f5-9337-4f1e-82f6-42fd04df3c47',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/31a64053-2778-46ba-b82f-6bd3d6418a16.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/c45b3466-be7f-4a52-8586-f4f9e9371374',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/lynnfernandez-clt_today_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Voted Best Barre Instructor',
        caption_name: '',
        image: `${ASSET_HOST}/lynnfernandez-charlotte_five_logo.png`,
        video: '',
      },
    ],
  },
  dmmaltby: {
    user_id: 45,
    bio_backdrop: `${ASSET_HOST}/dmmaltby_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/e58112dc-7d9c-490e-af8d-85ce52537789.m3u8',
    class_image: `${ASSET_HOST}/dmmaltby.png`,
    class_video: `${ASSET_HOST}/dmmaltby.mp4`,
    name: 'Danielle Matlby',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/70498cc1-ecf1-420f-b076-12eeb00dbf88',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/9ce17ada-7c60-4d74-986f-c8518aa9b7cd.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/2a4051ad-3f12-4e80-818e-71af8ca6a6b6',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/dmmaltby-the_bachelor.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/dmmaltby-bachelor_paradise.jpg`,
        video: '',
      },
    ],
  },
  lilysaito: {
    user_id: 47,
    bio_backdrop: `${ASSET_HOST}/lilysaito_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/3944e82d-77a1-46a7-86d8-1ca3b271bbf5.m3u8',
    class_image: `${ASSET_HOST}/lilysaito.png`,
    class_video: `${ASSET_HOST}/lilysaito.mp4`,
    name: 'Lily Saito',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/c36d30b2-c369-4766-9ed6-6cd8c4995cfa',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/8e1f77ea-0cc6-4ca5-967a-f7db3b1b3f1c.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/2cb9f870-5a75-447c-837f-e56247b929bb',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/lilysaito-nashville_ballet.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/lilysaito-young_arts_foundation.jpg`,
        video: '',
      },
    ],
  },
  noellyanikfitness: {
    user_id: 34,
    bio_backdrop: `${ASSET_HOST}/noellyanikfitness_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/0f394922-551a-40c4-9268-52b155f128d2.m3u8',
    class_image: `${ASSET_HOST}/noellyanikfitness.png`,
    class_video: `${ASSET_HOST}/noellyanikfitness.mp4`,
    name: 'Noell Yanik',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/d31491d0-bbda-4f47-8c50-e46ce285d203',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/7a57fc66-0673-48a8-841e-9255b1979f5a.m3u8',
    picture:
      'https://s3.amazonaws.com/bnafit-image/b50f5f1c-36be-4c8c-ab99-e754107bdc1d',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/noellyanikfitness-womens_health_magazine.jpg`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/noellyanikfitness-us_news.jpeg`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/noellyanikfitness-charlotte_observer.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/noellyanikfitness-courier_journal.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/noellyanikfitness-huffpost.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/noellyanikfitness-good_morning_america.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/noellyanikfitness-fox_46.png`,
        video: '',
      },
    ],
  },
  paigefuson: {
    user_id: 42,
    bio_backdrop: `${ASSET_HOST}/paigefuson_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/727cf782-b86d-460f-909b-0531910afcd3.m3u8',
    class_image: `${ASSET_HOST}/paigefuson.png`,
    class_video: `${ASSET_HOST}/paigefuson.mp4`,
    name: 'Paige Fuson',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/d07c4d11-32be-4507-8b59-d8eb1f752868',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/4ccb2f88-3ef9-4fe6-bf9b-9011b847cb03.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/0a266aa0-5c5c-4ed0-b1c6-1006411e94bb',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/paigefuson-tylt_logo.png`,
        video: '',
      },
    ],
  },
  justbnfit: {
    user_id: 8,
    bio_backdrop: `${ASSET_HOST}/justbnfit_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/5f57064b-03b7-4cf2-95a7-10502f714b97.m3u8',
    class_image: `${ASSET_HOST}/justbnfit.png`,
    class_video: `${ASSET_HOST}/justbnfit.mp4`,
    name: 'Bre Leach',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/ea879d20-ed39-4a61-aaaf-3d05b3ecc954',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/249c56e2-29a1-4ac8-9f68-639d7515aae1.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/8efd9d34-2007-480a-b10a-0ea5ab431f26',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/justbnfit-womens_health_logo.gif`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/justbnfit-fabletics_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/justbnfit-sweat_net_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/justbnfit-charlotte_five_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/justbnfit-smoothie_king.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/justbnfit-pretty_girls_sweat.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/justbnfit-novant_health_logo.png`,
        video: '',
      },
    ],
  },
  brandonparker: {
    user_id: 12,
    bio_backdrop: `${ASSET_HOST}/brandonparker_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/57dc7f7f-a3b8-447c-9940-965515a2585a.m3u8',
    class_image: `${ASSET_HOST}/brandonparker.png`,
    class_video: `${ASSET_HOST}/brandonparker.mp4`,
    name: 'Brandon Parker',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/b1883901-4ae3-4d9f-9a35-7a6589cc6fb3',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/b071827e-9052-434d-820e-2725b8f4c5b6.m3u8',
    picture:
      'https://s3.amazonaws.com/bnafit-image/558ec5f6-7fb8-422a-a160-d6f5ce736681',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/brandonparker-usa_cheer.jpg`,
        video: '',
      },
    ],
  },
  cameronbarbour1: {
    user_id: 13,
    bio_backdrop: `${ASSET_HOST}/cameronbarbour1_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/f9faf975-da49-41c2-9039-cf9ccf74057f.m3u8',
    class_image: `${ASSET_HOST}/cameronbarbour1.png`,
    class_video: `${ASSET_HOST}/cameronbarbour1.mp4`,
    name: 'Cameron Barbour',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/0a5f26f5-6702-42e9-8dfc-20fd5d8b85aa',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/dd2e7466-c97d-4d61-b49a-c5b0f8a6d1c4.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/9140d13b-977c-43d0-aae3-b5ddfc9b252b',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/cameronbarbour1-cheerleading_worlds.png`,
        video: '',
      },
    ],
  },
  crystaldavis: {
    user_id: 14,
    bio_backdrop: `${ASSET_HOST}/crystaldavis_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/47f8f930-e61f-479f-916e-10a567f79936.m3u8',
    class_image: `${ASSET_HOST}/crystaldavis.png`,
    class_video: `${ASSET_HOST}/crystaldavis.mp4`,
    name: 'Crystal Davis',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/6a3e8e74-cef8-4070-9b0e-ab571aaefa65',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/99c65390-c28b-4902-91a3-754171369527.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/f5a736d3-3860-4da8-b12e-57e2ab954f93',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/crystaldavis-cheerleading_worlds.png`,
        video: '',
      },
    ],
  },
  katiecollard: {
    user_id: 31,
    bio_backdrop: `${ASSET_HOST}/katiecollard_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/969ad2ea-155a-491e-a02f-fbd1d4b7ce7d.m3u8',
    class_image: `${ASSET_HOST}/katiecollard.png`,
    class_video: `${ASSET_HOST}/katiecollard.mp4`,
    name: 'Katie Collard',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/7fe51cb8-00d9-47cf-a7b4-346a14309780',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/de020163-af94-4883-88ca-c521ac1eebc5.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/968ad71f-976f-4547-a01a-8e08ad7cb0c3',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/katiecollard-active_life_dc.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/katiecollard-medium.png`,
        video: '',
      },
    ],
  },
  yogaplayground: {
    user_id: 53,
    bio_backdrop: `${ASSET_HOST}/yogaplayground_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/07bb2f06-b807-4dae-8df0-74be56e380c4.m3u8',
    class_image: `${ASSET_HOST}/yogaplayground.png`,
    class_video: `${ASSET_HOST}/yogaplayground.mp4`,
    name: 'Eazy',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/7e8fa896-631c-4717-9d37-835274793dd8',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/98f2d4c2-0ad5-4365-aa00-a2ee28f31a63.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/10a999a9-557c-42b9-a375-9c78b32dd234',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/yogaplayground-voyage_atl.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/yogaplayground-ryt_logo.png`,
        video: '',
      },
    ],
  },
  hannahbeth: {
    user_id: 51,
    bio_backdrop: `${ASSET_HOST}/hannahbeth_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/e31af9cc-3939-4f0b-b6ef-9b3cede6ac58.m3u8',
    class_image: `${ASSET_HOST}/hannahbeth.png`,
    class_video: `${ASSET_HOST}/hannahbeth.mp4`,
    name: 'Hannah Beth Millman',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/9967f3c2-c0ed-4821-87db-7822a5538a91',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/dd809167-5078-4c60-a045-d12675b41c0c.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/5c3727c6-b284-4b2f-9d59-4f43d4355938',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/hannahbeth-atlanta_magazine_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/hannahbeth-yogaworks_logo.png`,
        video: '',
      },
    ],
  },
  movingwithmatt: {
    user_id: 52,
    bio_backdrop: `${ASSET_HOST}/movingwithmatt_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/99fbaf30-f3ac-410b-84a6-0b21ebef45a7.m3u8',
    class_image: `${ASSET_HOST}/movingwithmatt.png`,
    class_video: `${ASSET_HOST}/movingwithmatt.mp4`,
    name: 'Matt Thomas',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/ae665a02-7b9c-4d79-af6a-8098ccb6578b',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/d9bcb450-7b5b-4f30-b2f5-009b22d94de1.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/53a69aea-3c63-410c-b625-35f21391508b',
    trusted: [
      {
        title: '',
        caption: 'Chess-Boxing 90kg World Champion',
        caption_name: 'Captain of Team USA',
        image: `${ASSET_HOST}/movingwithmatt-usa_boxing.jpg`,
        video: '',
      },
      {
        title: '',
        caption: 'Hosted First Combat Sporting Event',
        caption_name: 'at Mercedes Benz Stadium',
        image: `${ASSET_HOST}/movingwithmatt-meredeces_benz_stadium.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Netflix Reality Show Star',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-love_is_blind_logo.jpg`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-lululemon_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-simplybuckhead.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-entrepreneur_magazine_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-ajc_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-modern_luxury_mens_book.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-jezebel.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-hypotamus.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-dana_barrett_show.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-the_biz.jpg`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/movingwithmatt-brawl_for_a_cause.png`,
        video: '',
      },
    ],
  },
  mariebellepr: {
    user_id: 32,
    bio_backdrop: `${ASSET_HOST}/mariebellepr_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/4e9e3837-488e-4856-b0e6-db2bac663447.m3u8',
    class_image: `${ASSET_HOST}/mariebellepr.png`,
    class_video: `${ASSET_HOST}/mariebellepr.mp4`,
    name: 'Marie Belle Perez Rivera',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/3412b386-8159-4b1c-8a68-4ddbe229d3f0',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/2b4ace1f-d485-4b06-be15-f5146c5ffd7f.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/3cbff8e7-bd64-4993-b4d8-fa9286467b00',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/mariebellepr-the_washington_post.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/mariebellepr-mantra_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/mariebellepr-sweat-logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/mariebellepr-active_life_dc.png`,
        video: '',
      },
    ],
  },
  raven: {
    user_id: 30,
    bio_backdrop: `${ASSET_HOST}/raven_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/4002b54b-aeac-4286-9bad-628bea3a096f.m3u8',
    class_image: `${ASSET_HOST}/raven.png`,
    class_video: `${ASSET_HOST}/raven.mp4`,
    name: '',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/565c14a8-5434-4013-836d-e4ca35f93670',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/229b223d-072f-4395-904d-069d0072af47.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/ade729d3-0cdb-42e3-8a16-934b3a2d45b4',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/raven-washingtonian.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/raven-posh_seven.png`,
        video: '',
      },
    ],
  },
  leighannyeager: {
    user_id: 16,
    bio_backdrop: `${ASSET_HOST}/leighannyeager_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/f8388127-eee8-4a0f-b7dd-4bcdceea5115.m3u8',
    class_image: `${ASSET_HOST}/leighannyeager.png`,
    class_video: `${ASSET_HOST}/leighannyeager.mp4`,
    name: 'Leigh Ann Yeager',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/0ec38f3e-469b-4e5c-a697-ea7e3d837879',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/0320cee3-2119-4eff-83e5-4dfe767066ea.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/405cb441-8f37-4191-b9b5-28407e40f376',
    trusted: [
      {
        title: '',
        caption: 'Voted Best Personal Trainer',
        caption_name: 'in the Triangle',
        image: `${ASSET_HOST}/leighannyeager-indy_week_best.png`,
        video: '',
      },
      {
        title: '',
        caption: 'National Fitness Competitor',
        caption_name: '',
        image: `${ASSET_HOST}/leighannyeager-national_fitness_competition.png`,
        video: '',
      },
    ],
  },
  fitnesswithnassa: {
    user_id: 35,
    bio_backdrop: `${ASSET_HOST}/fitnesswithnassa_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/19266805-d8fb-4840-a864-81517128fd9b.m3u8',
    class_image: `${ASSET_HOST}/fitnesswithnassa.png`,
    class_video: `${ASSET_HOST}/fitnesswithnassa.mp4`,
    name: 'Janassa Kearney',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/8b8dd499-ebae-4988-ac37-831caa4bc049',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/7adee3f8-0ca5-4959-ab18-f2fc4d7ecb0f.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/250e8bca-acf4-4adf-8b8d-9fc96fc04787',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/fitnesswithnassa-fox_46.png`,
        video: '',
      },
    ],
  },
  leahmward: {
    user_id: 4,
    bio_backdrop: `${ASSET_HOST}/leahmward_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/8cf06158-8ded-4a1f-a928-aee722e20d30.m3u8',
    class_image: `${ASSET_HOST}/leahmward.png`,
    class_video: `${ASSET_HOST}/leahmward.mp4`,
    name: 'Leah Ward',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/88457bb5-4e36-41c6-b404-5fd3fd778f39',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/104cc1e5-5b86-47db-844c-802049c46aef.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/fd4237cc-a745-46ec-be40-4beeb1dc797d',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/leahmward-moonchild_yoga.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/leahmward-yogi_approved.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Co-Owner of Indy Award Winner',
        caption_name: 'for Best Pilates Studio, Barre-Up',
        image: `${ASSET_HOST}/leahmward-indy_week_best.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/leahmward-abc11logo.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Midtown Magazine',
        caption_name: 'Diamond Award Winner',
        image: `${ASSET_HOST}/leahmward-diamond_awards.jpg`,
        video: '',
      },
      {
        title: '',
        caption: "Triangle Downtownder's Readers Favorite for",
        caption_name: 'Best Yoga/Barre/Pilates Studio',
        image: `${ASSET_HOST}/leahmward-triangle_downtowner.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Voted Classpass',
        caption_name: 'Top Instructor',
        image: `${ASSET_HOST}/jojopolk-classpass_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: 'Soccer Collegiate Athlete',
        caption_name: '',
        image: `${ASSET_HOST}/leahmward-west_virginia_university.png`,
        video: '',
      },
    ],
  },
  ashleyfarrar: {
    user_id: 11,
    bio_backdrop: `${ASSET_HOST}/ashleyfarrar_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/96d54a23-ae0a-4633-a9c1-3281aa125662.m3u8',
    class_image: `${ASSET_HOST}/ashleyfarrar.png`,
    class_video: `${ASSET_HOST}/ashleyfarrar.mp4`,
    name: 'Ashley Farrar',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/0a560887-c380-4383-b357-1a25f002247b',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/9d7fa724-5878-4079-8714-f31dde40577f.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/60151be0-0f02-4071-b972-783462532486',
    trusted: [],
  },
  bambiwillow: {
    user_id: 44,
    bio_backdrop: `${ASSET_HOST}/bambiwillow_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/f34cb64a-6024-4b28-930d-68f3f145e5e2.m3u8',
    class_image: `${ASSET_HOST}/bambiwillow.png`,
    class_video: `${ASSET_HOST}/bambiwillow.mp4`,
    name: 'Bambi Watt',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/9bbbec10-292e-40f9-8ed8-c9b5c14dedb3',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/9c2bd00c-1f5c-428f-916b-7afb21666b16.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/ad7b258f-56be-4d66-bb9d-945bfa41535f',
    trusted: [],
  },
  connertraywick: {
    user_id: 6,
    bio_backdrop: `${ASSET_HOST}/connertraywick_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/3d539825-1e8d-4972-aaab-07b71270df23.m3u8',
    class_image: `${ASSET_HOST}/connertraywick.png`,
    class_video: `${ASSET_HOST}/connertraywick.mp4`,
    name: 'Conner Traywick',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/15e9ed92-c62d-4956-b7d9-57c06f90fcc7',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/ef938a85-fe51-4de1-9a70-248519aa9787.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/1ce5790a-ba00-48c8-9de1-f1c16a6d172e',
    trusted: [],
  },
  crystalseaver: {
    user_id: 37,
    bio_backdrop: `${ASSET_HOST}/crystalseaver_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/3b75b865-0ec4-42d0-b865-e6995c3db55e.m3u8',
    class_image: `${ASSET_HOST}/crystalseaver.png`,
    class_video: `${ASSET_HOST}/crystalseaver.mp4`,
    name: 'Crystal Seaver',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/8abed21b-1701-4aa9-a5ff-55f4c4110feb',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/d8a2a566-9e21-4503-a0fb-f74d6f4fbd5d.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/8800242d-9a2b-4e2e-98ef-dcaf2e3a920d',
    trusted: [],
  },
  getfitwithchrys: {
    user_id: 55,
    bio_backdrop: `${ASSET_HOST}/getfitwithchrys_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/f6620328-0576-4838-979d-529d885c5e23.m3u8',
    class_image: `${ASSET_HOST}/getfitwithchrys.png`,
    class_video: `${ASSET_HOST}/getfitwithchrys.mp4`,
    name: 'Chrys Crockett',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/fa08db6e-3d20-40c6-9a41-69b3d935ac92',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/e7c09920-7c29-4c90-91d6-0aa7f4da57c8.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/41a7a29e-f527-4010-9771-cdb80754e0a8',
    trusted: [],
  },
  jenwdufresne: {
    user_id: 36,
    bio_backdrop: `${ASSET_HOST}/jenwdufresne_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/c1da7d53-1926-49ab-8169-ce6a9635d1bf.m3u8',
    class_image: `${ASSET_HOST}/jenwdufresne.png`,
    class_video: `${ASSET_HOST}/jenwdufresne.mp4`,
    name: 'Jen Dufresne',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/67faae21-c5d3-4b39-9420-c847ced4a340',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/eb0b2465-2f35-421f-888e-7ac64b181a3b.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/985a60ab-5b4b-488e-8dca-cac2e5a39e4d',
    trusted: [],
  },
  laurcederstrom: {
    user_id: 26,
    bio_backdrop: `${ASSET_HOST}/laurcederstrom_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/0d3ce88a-9984-4b4d-9300-d8e49b0ece92.m3u8',
    class_image: `${ASSET_HOST}/laurcederstrom.png`,
    class_video: `${ASSET_HOST}/laurcederstrom.mp4`,
    name: 'Lauren Cederstrom',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/cbb96ed1-a348-4243-830b-121a57e83994',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/848d41fc-ee27-492c-ba21-d7e17ee5bebe.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/a2b785e5-79d1-46b8-b082-106225f6a3eb',
    trusted: [],
  },
  loganaldridge: {
    user_id: 23,
    bio_backdrop: `${ASSET_HOST}/loganaldridge_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/e3e412f9-2218-4946-b39a-f5d45c35be7f.m3u8',
    class_image: `${ASSET_HOST}/loganaldridge.png`,
    class_video: `${ASSET_HOST}/loganaldridge.mp4`,
    name: 'Logan Aldridge',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/ba725cb0-94de-43a7-af32-503d39fb0f89',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/1ee952d1-866e-4d48-87a9-d19705af1a99.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/102f0148-3cef-4fcc-881d-d5781ab71f34',
    trusted: [],
  },
  mintzflow: {
    user_id: 19,
    bio_backdrop: `${ASSET_HOST}/mintzflow_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/685cf38a-fe53-4c7e-b65d-c35e844af359.m3u8',
    class_image: `${ASSET_HOST}/mintzflow.png`,
    class_video: `${ASSET_HOST}/mintzflow.mp4`,
    name: 'Ryan Mintz',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/7aa29e0c-72cc-4cfc-9a55-306fcf357d1f',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/460193fd-5233-44ac-b2df-64dbf36cd921.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/2b019563-53dd-4279-911c-cbbe54658e93',
    trusted: [],
  },
  nelsoncuadras: {
    user_id: 33,
    bio_backdrop: `${ASSET_HOST}/nelsoncuadras_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/cb03c77a-7fa5-4257-9434-f5d648ff0a65.m3u8',
    class_image: `${ASSET_HOST}/nelsoncuadras.png`,
    class_video: `${ASSET_HOST}/nelsoncuadras.mp4`,
    name: 'Nelson Cuadras',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/7f1eda32-86d8-4ede-b2c6-0067e751b31d',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/6b32cf1c-bba3-40cc-954f-8bce2da2d1c1.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/9e2c6f99-e40b-4bd0-889f-accc5425a0e4',
    trusted: [],
  },
  rocsiglobalfitness: {
    user_id: 49,
    bio_backdrop: `${ASSET_HOST}/rocsiglobalfitness_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/945f23d7-e392-4b43-b092-d9da9cfb5cd4.m3u8',
    class_image: `${ASSET_HOST}/rocsiglobalfitness.png`,
    class_video: `${ASSET_HOST}/rocsiglobalfitness.mp4`,
    name: 'Rocsi',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/575eb457-b922-47dc-8fe6-35a4984172be',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/6111da0f-15bf-401e-acf9-286ab7ffc144.m3u8',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/cc9697c4-abd4-4007-9ed2-5691ec0e6506',
    trusted: [],
  },
  tanasialea: {
    user_id: 46,
    bio_backdrop: `${ASSET_HOST}/tanasialea_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/f4053802-d2bb-40f7-a383-a79d9b2361ad.m3u8',
    class_image: `${ASSET_HOST}/tanasialea.png`,
    class_video: `${ASSET_HOST}/tanasialea.mp4`,
    name: 'Tate Lea',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/aa70a6d5-4f66-489c-a130-63967ff6b26c',
    playbook_video: `${ASSET_HOST}/joshrogers_workout_20200205T214210.m3u8`,
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/0869441b-ce8f-402e-ab30-ac83e0fd6a71',
    trusted: [],
  },
  winterholbrook: {
    user_id: 10,
    bio_backdrop: `${ASSET_HOST}/winterholbrook_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/2e6fd2c8-a2c1-4440-9103-55b6f2798f49.m3u8',
    class_image: `${ASSET_HOST}/winterholbrook.png`,
    class_video: `${ASSET_HOST}/winterholbrook.mp4`,
    name: 'Winter Holbrook',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/07f1b04f-d9a9-4e29-ae61-c041ae6110db',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/9202b0c8-c06b-4dbf-88a9-b42448478b48.m3u8',
    picture:
      'https://s3.amazonaws.com/bnafit-image/b7eba5f6-5140-4117-bc7d-7d8b4a200240',
    trusted: [],
  },
  _errickpt: {
    // disabled
    user_id: 22,
    bio_backdrop: `${ASSET_HOST}/errickpt_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/d2a121d4-9ca8-4ca5-a56f-dcf282f544db.m3u8',
    class_image: `${ASSET_HOST}/errickpt.png`,
    class_video: `${ASSET_HOST}/errickpt.mp4`,
    name: 'Errick McAdams',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/646c2e86-4f6d-4f7b-a268-0354a24b18bf',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/a3319673-b93f-49d1-af57-38f0cbaabb95.m3u8',
    picture:
      'https://s3.amazonaws.com/bnafit-image/8c4c0bc5-e4ef-4288-9dfe-b14346217bb1',
    trusted: [
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-shape.jpg`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-self_logo.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-mens_journal.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-the_washington_post.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-train_magazine.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-washingtonian.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-wanderlust.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-active_life_dc.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-capitol_file.png`,
        video: '',
      },
      {
        title: '',
        caption: '',
        caption_name: '',
        image: `${ASSET_HOST}/errickpt-washington_life.jpg`,
        video: '',
      },
    ],
  },
  lidiaortega: {
    user_id: 17,
    bio_backdrop: `${ASSET_HOST}/lidiaortega_bio.png`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/a77aec34-21d5-4a6f-ab00-350444c986c6.m3u8',
    class_image: `${ASSET_HOST}/lidiaortega.png`,
    class_video: `${ASSET_HOST}/lidiaortega.mp4`,
    name: 'Lidia Ortega',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/ee271c96-d6a0-4324-b7db-e1973fa596d7',
    playbook_video:
      'https://d2n58e84t9klur.cloudfront.net/5352eb4b-268f-4506-b8ba-1fea348c1d86.m3u8',
    picture:
      'https://s3.amazonaws.com/bnafit-image/8851f562-fc30-4b89-9784-18b8f30746ed',
    trusted: [],
  },
  jefftoney: {
    user_id: 50,
    bio_backdrop: `${ASSET_HOST}/jefftoney_bio.jpg`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/095a7e4f-1b0f-4a25-adf4-d25a65d689de.m3u8',
    class_image: `${ASSET_HOST}/jefftoney.png`,
    class_video: `${ASSET_HOST}/jefftoney.mp4`,
    name: 'Jeff Toney',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/dcc13866-eb66-41d7-9e1f-37488e4e7f57',
    playbook_video: '',
    picture:
      'https://bnafit-image.s3.amazonaws.com/750b83e6-7240-48df-a44d-c31ec05d2610',
    trusted: [],
  },
  titusunlimited: {
    user_id: 1594,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/17805fbb-4fa6-44a2-b5c9-2fd7e0cb96ef',
    bio_video: '',
    class_image: `${ASSET_HOST}/titusunlimited.png`,
    class_video: `${ASSET_HOST}/titusunlimited.mp4`,
    name: 'Titus Unlimited',
    playbook_image:
      'https://bnafit-image.s3.amazonaws.com/6d9772c0-5bd6-44e7-a6d0-3b7d84a10f71',
    playbook_video: '',
    picture:
      'https://bnafit-image.s3.amazonaws.com/6d9772c0-5bd6-44e7-a6d0-3b7d84a10f71',
    trusted: [],
  },
  mubarakmalik: {
    user_id: 1795,
    bio_backdrop: `${ASSET_HOST}/mubarakmalik_bio.jpg`,
    bio_video: '',
    class_image: `${ASSET_HOST}/mubarakmalik.png`,
    class_video: `${ASSET_HOST}/mubarakmalik.mp4`,
    name: 'Bar Malik',
    playbook_image:
      'https://bnafit-image.s3.amazonaws.com/d1729664-f523-46c2-b415-cc9a7c20230c',
    playbook_video: '',
    picture:
      'https://bnafit-image.s3.amazonaws.com/d1729664-f523-46c2-b415-cc9a7c20230c',
    trusted: [
      {
        title: 'Trusted Trainer For',
        caption: 'Pro Basketball Player for the Los Angeles Sparks',
        caption_name: 'Chelsea Gray',
        image: `${ASSET_HOST}/mubarakmalik-chelsea_gray.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Pro Basketball Player for the New York Knicks',
        caption_name: 'Elfrid Payton',
        image: `${ASSET_HOST}/mubarakmalik-elfrid_payton.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Pro Basketball Player for the New York Knicks',
        caption_name: 'Moe Harkless',
        image: `${ASSET_HOST}/mubarakmalik-moe_harkless.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Pro Basketball Player for the New York Knicks',
        caption_name: 'Frank Ntilikina',
        image: `${ASSET_HOST}/mubarakmalik-frank_ntilikina.png`,
        video: '',
      },
      {
        title: 'Trusted Trainer For',
        caption: 'Pro Basketball Player for the New York Knicks',
        caption_name: 'Mitchell Robinson',
        image: `${ASSET_HOST}/mubarakmalik-mitchell_robinson.png`,
        video: '',
      },
    ],
  },
  dynastihunt: {
    user_id: 1909,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/ac5c7803-3f23-4435-8ad1-b1a31d2b2ebd',
    bio_video: '',
    class_image: `${ASSET_HOST}/dynastihunt.png`,
    class_video: `${ASSET_HOST}/dynastihunt.mp4`,
    name: 'Dynasti Hunt',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/507429e0-09ad-493a-bced-07cd1be05c55',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/507429e0-09ad-493a-bced-07cd1be05c55',
    trusted: [],
  },
  karadshehfit: {
    user_id: 2389,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/d1101ef0-99a8-4d9b-9939-6792187c6246',
    bio_video: '',
    class_image: `${ASSET_HOST}/karadshehfit.png`,
    class_video: `${ASSET_HOST}/karadshehfit.mp4`,
    name: 'Lynneise Karadsheh',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/b884486b-9f4d-4831-a853-61e429323871',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/b884486b-9f4d-4831-a853-61e429323871',
    trusted: [],
  },
  laurenarps: {
    user_id: 2452,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/5b188aca-f693-431e-aad1-d79c0c801b3e',
    bio_video: '',
    class_image: `${ASSET_HOST}/laurenarps.png`,
    class_video: `${ASSET_HOST}/laurenarps.mp4`,
    name: 'Lauren Arps',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/0433be0b-5b2a-4dbd-bd5e-55e308977aa5',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/0433be0b-5b2a-4dbd-bd5e-55e308977aa5',
    trusted: [],
  },
  queen_beezy13: {
    user_id: 2482,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/a46d5f7e-6bf1-4c35-9bf2-42abccc0b728',
    bio_video: '',
    class_image: `${ASSET_HOST}/queen_beezy13.png`,
    class_video: `${ASSET_HOST}/queen_beezy13.mp4`,
    name: 'Brooklynn Sittner',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/6e4640f0-9fbf-4cfe-b97a-67232f4812ed',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/6e4640f0-9fbf-4cfe-b97a-67232f4812ed',
    trusted: [],
  },
  meghanlalor: {
    user_id: 2481,
    bio_backdrop:
      'https://d2n58e84t9klur.cloudfront.net/9713b368-ca01-4346-bbe5-07b1e400df1d/9713b368-ca01-4346-bbe5-07b1e400df1d_20200608T170534.0000000.jpg',
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/9713b368-ca01-4346-bbe5-07b1e400df1d/9713b368-ca01-4346-bbe5-07b1e400df1d_20200608T170535.m3u8',
    class_image: `${ASSET_HOST}/meghanlalor.png`,
    class_video: `${ASSET_HOST}/meghanlalor.mp4`,
    name: 'Meghan Lalor',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/df949946-637d-4bdf-ac87-76c14bf73a42',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/df949946-637d-4bdf-ac87-76c14bf73a42',
    trusted: [],
  },
  thichapilates: {
    user_id: 2512,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/0e376152-aff6-4a60-bb58-2ffd4b47b234',
    bio_video: '',
    class_image: `${ASSET_HOST}/thichapilates.png`,
    class_video: `${ASSET_HOST}/thichapilates.mp4`,
    name: 'Thicha',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/c7f50d7d-f384-4022-a601-163d33bc2185',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/c7f50d7d-f384-4022-a601-163d33bc2185',
    trusted: [],
  },
  davidhigginslondon: {
    user_id: 2388,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/36b36123-2411-43a4-9dbf-afd05fa2e568',
    bio_video: '',
    class_image: `${ASSET_HOST}/davidhigginslondon.png`,
    class_video: `${ASSET_HOST}/davidhigginslondon.mp4`,
    name: 'David Higgins',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/9b4ab2ac-5e34-4609-8680-b4ed34d78d7b',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/9b4ab2ac-5e34-4609-8680-b4ed34d78d7b',
    trusted: [
      {
        title: 'Has Worked With',
        caption: 'Actor',
        caption_name: 'Kumail Nanjiani',
        image: `${ASSET_HOST}/davidhigginslondon-kumail_nanjiani.png`,
        video: '',
      },
      {
        title: 'Has Worked With',
        caption: 'Actress',
        caption_name: 'Margot Robbie',
        image: `${ASSET_HOST}/davidhigginslondon-margot_robbie.png`,
        video: '',
      },
      {
        title: 'Has Worked With',
        caption: 'Actor',
        caption_name: 'Samuel L. Jackson',
        image: `${ASSET_HOST}/davidhigginslondon-samuel_l_jackson.png`,
        video: '',
      },
      {
        title: 'Has Worked With',
        caption: 'Acress',
        caption_name: 'Claudia Schiffer',
        image: `${ASSET_HOST}/davidhigginslondon-claudia_schiffer.png`,
        video: '',
      },
    ],
  },
  lisakayepilates: {
    user_id: 2589,
    bio_backdrop: `${ASSET_HOST}/lisakayepilates_bio.png`,
    bio_video:
      'https://d2n58e84t9klur.cloudfront.net/6ef5de67-6de8-4c83-b63d-e3e60dc52d96.m3u8',
    class_image: `${ASSET_HOST}/lisakayepilates.png`,
    class_video: `${ASSET_HOST}/lisakayepilates.mp4`,
    name: 'Lisa Kaye',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/18d36d83-d7c7-476a-b5a8-28b102e41f26',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/18d36d83-d7c7-476a-b5a8-28b102e41f26',
    trusted: [],
  },
  lisahubbardpilates: {
    user_id: 2514,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/f9052d5d-46ad-47ef-b556-b3c4a7916a49',
    bio_video: '',
    class_image: `${ASSET_HOST}/lisahubbardpilates.png`,
    class_video: `${ASSET_HOST}/lisahubbardpilates.mp4`,
    name: 'Lisa Hubbard',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/de4b6c0f-6350-4897-a850-3f86d96773cf',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/de4b6c0f-6350-4897-a850-3f86d96773cf',
    trusted: [],
  },
  philfit: {
    user_id: 2610,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/a5696a7d-27ad-4153-abc1-605c47ee647b',
    bio_video: '',
    class_image:
      'https://d4vdobyufbs6d.cloudfront.net/a5696a7d-27ad-4153-abc1-605c47ee647b',
    class_video: '',
    name: 'Phil Fit',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/a5696a7d-27ad-4153-abc1-605c47ee647b',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/a5696a7d-27ad-4153-abc1-605c47ee647b',
    logo: 'https://d3sivlfumj4n3y.cloudfront.net/philfit_logo.jpg',
    trusted: [],
  },
  lindseymariefit: {
    user_id: 3403,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/fc913054-e028-4dcb-afef-1ce5388426b3',
    bio_video: '',
    class_image:
      'https://d4vdobyufbs6d.cloudfront.net/fc913054-e028-4dcb-afef-1ce5388426b3',
    class_video: '',
    name: 'Lindsey Greeley',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/fc913054-e028-4dcb-afef-1ce5388426b3',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/fc913054-e028-4dcb-afef-1ce5388426b3',
    trusted: [],
  },
  thefitnesslioness: {
    user_id: 2685,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/public/7a9ff9fc-3c27-4ef7-872c-d330c5555085',
    bio_video: '',
    class_image:
      'https://d4vdobyufbs6d.cloudfront.net/public/7a9ff9fc-3c27-4ef7-872c-d330c5555085',
    class_video: '',
    name: 'Sarah Manns',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/public/7a9ff9fc-3c27-4ef7-872c-d330c5555085',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/public/7a9ff9fc-3c27-4ef7-872c-d330c5555085',
    logo: 'https://d3sivlfumj4n3y.cloudfront.net/thefitnesslioness_logo.png',
    trusted: [],
  },
  'olivia-poling': {
    user_id: 3277,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/eee64708-2f27-4e8e-9f42-0bb95fc001a6',
    bio_video: '',
    class_image:
      'https://d4vdobyufbs6d.cloudfront.net/eee64708-2f27-4e8e-9f42-0bb95fc001a6',
    class_video: '',
    name: 'Olivia Poling',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/eee64708-2f27-4e8e-9f42-0bb95fc001a6',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/eee64708-2f27-4e8e-9f42-0bb95fc001a6',
    trusted: [],
  },
  sweatforthesoul: {
    user_id: 4933,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/922c2ebb-82ca-41fc-9854-837a83bb92e7',
    bio_video: '',
    class_image:
      'https://d4vdobyufbs6d.cloudfront.net/922c2ebb-82ca-41fc-9854-837a83bb92e7',
    class_video: '',
    name: 'Natalie Bally',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/922c2ebb-82ca-41fc-9854-837a83bb92e7',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/922c2ebb-82ca-41fc-9854-837a83bb92e7',
    trusted: [],
  },
  dillonvibes: {
    user_id: 2478,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/b65302d6-cd36-4b38-892a-4de2eca0a762',
    bio_video: '',
    class_image:
      'https://d4vdobyufbs6d.cloudfront.net/b65302d6-cd36-4b38-892a-4de2eca0a762',
    class_video: '',
    name: 'Dillon Vibes',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/b65302d6-cd36-4b38-892a-4de2eca0a762',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/b65302d6-cd36-4b38-892a-4de2eca0a762',
    trusted: [],
  },
  thedailyglow: {
    user_id: 5167,
    bio_backdrop:
      'https://d4vdobyufbs6d.cloudfront.net/11314946-a880-470b-8001-4b4822d54741',
    bio_video: '',
    class_image:
      'https://d4vdobyufbs6d.cloudfront.net/11314946-a880-470b-8001-4b4822d54741',
    class_video: '',
    name: 'Tara Crosley',
    playbook_image:
      'https://d4vdobyufbs6d.cloudfront.net/11314946-a880-470b-8001-4b4822d54741',
    playbook_video: '',
    picture:
      'https://d4vdobyufbs6d.cloudfront.net/11314946-a880-470b-8001-4b4822d54741',
    logo: 'https://d3sivlfumj4n3y.cloudfront.net/thedailyglow_logo.jpeg',
    trusted: [],
    prices: {
      monthly: 14,
      quarterly: 36,
      annual: 108,
    },
  },
};
