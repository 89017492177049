import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const Container = styled.div`
  padding: 4px 8px;
`;

export const PlanButtonContainer = styled.div``;

export const PlanButton = styled(Button)`
  && {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    padding: 16px;
    text-transform: none;
    width: 100%;
    &:hover {
      background: #fdf8ec;
      border: 1px solid #daac57;
      color: #6d502e;
    }
  }
`;

export const Cost = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const Popular = styled.div`
  background: #1e1f20;
  border-radius: 0px 0px 4px 4px;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  padding: 8px;
`;

export const ButtonText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`;

export const Period = styled.div`
  color: #6f6f6f;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const styles = {
  popularSelected: {
    background: '#daac57',
  },
  selectedPlan: {
    color: '#6d502e',
    background: '#fdf8ec',
    border: '1px solid #daac57',
  },
  unselectedPlan: {
    background: '#ffffff',
    border: '1px solid #e3e6e8',
  },
};
