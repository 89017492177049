import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { getContent } from '../../modules/Content';

import { Container, Content, LoadingContainer } from './styles.js';

class TermsOfUse extends React.Component {
  componentDidMount() {
    const { getContent } = this.props;
    getContent('terms_of_use', [], ['content', 'logo']);
  }

  render() {
    const {
      content: {
        terms_of_use: { data, isLoading },
      },
    } = this.props;

    return isLoading || data == null ? (
      <LoadingContainer>
        <CircularProgress size={70} style={{ color: '#caa854' }} />
      </LoadingContainer>
    ) : (
      <Container>
        {data.logo && (
          <img src={data.logo.url} alt="Strivers x The St. James" />
        )}
        <Content dangerouslySetInnerHTML={{ __html: data.content }} />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.contentState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent: getContent,
    },
    dispatch,
  );
}

TermsOfUse.propTypes = {
  content: PropTypes.object,
  getContent: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUse);
