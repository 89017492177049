import * as ACTION from './constants';

const initialState = {
  isRetrieving: false,
  generic: false,
  trainer: null,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.TRAINER_BASE:
      localStorage.removeItem('trainerState');
      return { ...initialState };
    case ACTION.GET_TRAINER:
      return { ...state, isRetrieving: true };
    case ACTION.SET_TRAINER:
      localStorage.setItem('trainerState', JSON.stringify(action.trainer));
      return { ...state, isRetrieving: false, trainer: action.trainer };
    case ACTION.SET_TRAINER_GENERIC:
      return { ...state, generic: action.generic };
    case ACTION.FINISHED_RETRIEVING:
      return { ...state, isRetrieving: false };
    default:
      return state;
  }
}
