import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { signOut } from './modules/Auth';
import { setCsrfToken } from './modules/Security';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { setGlobalLoadingSpinner } from './modules/GlobalUI';
import { getAccountSettings } from './modules/Settings';
import config from './core/config';
import Layout from './layouts';
import queryString from 'query-string';
import { STATIC_ROUTES } from './core/constants';

// window.LOG_LEVEL = 'DEBUG';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
    };
  }

  async componentDidMount() {
    try {
      await this.handleUserSignIn();
    } catch (err) {
      this.props.setGlobalLoadingSpinner(false);
      this.setState({ isAuthenticating: false });

      if (this.props.auth && this.props.auth.authError) {
        this.props.history.push('/login');
      }
    }
  }

  handleUserSignIn = async () => {
    let trainer = {};

    try {
      const trainerState = JSON.parse(localStorage.getItem('trainerState'));
      if (trainerState) {
        trainer.user_id = trainerState.trainer
          ? trainerState.trainer.user_id
          : trainerState.user_id;
        trainer.vidId = trainerState.vidId;
        trainer.promoCode = trainerState.promoCode;
        if (trainerState.site_data) {
          trainer.extra_data = {
            name: trainerState.site_data.name,
            site: trainerState.site_data.slug,
          };
        }
      }
    } catch (e) {
      console.log('unable to parse trainerState');
    }

    this.props.setGlobalLoadingSpinner(true);

    const settings = await this.props.getAccountSettings();
    this.handleCookie();
    this.setState({ isAuthenticated: true, isAuthenticating: false });
    this.props.setGlobalLoadingSpinner(false);
    this.handleRedirect(settings);
  };

  handleCookie = () => {
    const { cookies } = this.props;
    const csrftoken_cookie = cookies.get(config.api.csrfCookieName);
    const { csrftoken } = this.props.security;

    // if no token in state and there is a cookie, reset from cookie
    if (csrftoken_cookie && csrftoken_cookie !== csrftoken) {
      this.props.setCsrfToken(csrftoken_cookie);
    }
  };

  handleRedirect = (settings) => {
    const values = queryString.parse(this.props.location.search);

    // todo: refactor emails to use the same param
    // requires update to trainer portal also
    if (values.token || values.confirmation_code) {
      return;
    }

    // todo: refactor such that authOk in routes works again
    if (STATIC_ROUTES.indexOf(this.props.location.pathname) >= 0) {
      return;
    }

    // todo: store redirect in localstorage too and check it here (for facebook)

    // set the route to redirect the user
    if (values.redirect) {
      this.props.history.push(values.redirect);
    } else if (settings.premium_access || settings.premium_access_until) {
      this.props.history.push('/settings');
    } else {
      this.props.history.push('/subscription');
    }
  };

  handleLogout = async () => {
    await this.props.signOut();
    const { cookies } = this.props;

    cookies.remove(config.api.csrfCookieName, { path: '/' });
    this.props.setCsrfToken(undefined);

    this.setState({ isAuthenticated: false });
    this.props.history.push('/login');
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      handleLogout: this.handleLogout,
    };

    return !this.state.isAuthenticating && <Layout childProps={childProps} />;
  }
}

App.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.authState,
    security: state.securityState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAccountSettings: getAccountSettings,
      setGlobalLoadingSpinner: setGlobalLoadingSpinner,
      setCsrfToken: setCsrfToken,
      signOut: signOut,
    },
    dispatch,
  );
}

App.propTypes = {
  auth: PropTypes.shape({
    authError: PropTypes.object,
  }),
  getAccountSettings: PropTypes.func,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  security: PropTypes.shape({
    csrftoken: PropTypes.string,
  }),
  setCsrfToken: PropTypes.func,
  setGlobalLoadingSpinner: PropTypes.func,
  signOut: PropTypes.func,
};

export default compose(
  withRouter,
  withCookies,
  connect(mapStateToProps, mapDispatchToProps),
)(App);
