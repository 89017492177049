import axios from 'axios';
import config from './config';
import { signOut as signUserOut } from '../services/apiServices';

class AuthManager {
  async exchangeCode(code, code_verifier, trainer) {
    let response = await axios.post(
      `${config.api.base}/open_id_connect?client=web`,
      {
        code,
        code_verifier,
        trainer: trainer && trainer.user_id ? trainer.user_id : null,
      },
    );
    return response.data;
  }

  async signOut(token) {
    return await signUserOut(token);
  }

  async getUserData(idToken, trainer) {
    const payload = {
      idToken: idToken,
    };
    // send trainer if we have it...
    if (trainer) {
      payload.trainer = trainer;
    }
    let response = await axios.post(
      `${config.api.base}/authenticate/?client=web`,
      payload,
      { withCredentials: true },
    );
    return response.data;
  }
}

export default new AuthManager();
