import { combineReducers } from 'redux';

import { AUTH_BASE } from './Auth/constants';
import authState from './Auth/reducers';
import globalUIState from './GlobalUI/reducers';
import securityState from './Security/reducers';
import settingsState from './Settings/reducers';
import subscriptionState from './Subscription/reducers';
import customerState from './Customer/reducers';
import trainerState from './Trainer/reducers';
import metaState from './Meta/reducers';
import contentState from './Content/reducers';

const appReducer = combineReducers({
  authState,
  globalUIState,
  securityState,
  settingsState,
  subscriptionState,
  customerState,
  trainerState,
  metaState,
  contentState,
});

const preserveStates = ['contentState', 'trainerState'];

const rootReducer = (state, action) => {
  if (action.type === AUTH_BASE) {
    Object.keys(state).forEach(
      (stateKey) => preserveStates.includes(stateKey) || delete state[stateKey],
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
