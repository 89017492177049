import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { CircularProgress } from '@material-ui/core';

import { getContent } from '../../modules/Content';

import GooglePlay from '../../assets/google-play-badge.png';
import AppleStore from '../../assets/download-on-the-app-store.png';

import { APP_STORE_LINKS } from '../../core/constants';
import config from '../../core/config';

import {
  AppleStoreBadge,
  Badges,
  ContactUsLink,
  Content,
  GooglePlayBadge,
  HeroPageHeading,
  HeroPageSubheading,
  LoadingContainer,
  MiniDivider,
  NeedHelp,
} from './styles.js';

const platformMap = {
  Apple: APP_STORE_LINKS.Apple,
  Android: APP_STORE_LINKS.Android,
};

class DownloadApp extends React.Component {
  componentDidMount() {
    const { getContent } = this.props;
    getContent('download_app_page', ['hero', 'download_app_page_content']);

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleDownloadClick = (platform) => {
    window.open(platformMap[platform], '_blank');
  };

  renderPageHeading() {
    const {
      content: {
        download_app_page: {
          data: {
            download_app_page_content: { page_heading },
          },
        },
      },
    } = this.props;

    return (
      <div>
        {page_heading.page_heading_options === 'Logo' ? (
          <img src={page_heading.logo.url} />
        ) : (
          <div>{page_heading.heading}</div>
        )}
      </div>
    );
  }

  render() {
    const {
      content: {
        download_app_page: { data, isLoading },
      },
    } = this.props;

    return isLoading || data == null ? (
      <LoadingContainer>
        <CircularProgress size={70} style={{ color: '#caa854' }} />
      </LoadingContainer>
    ) : (
      <Content>
        <HeroPageHeading>{this.renderPageHeading()}</HeroPageHeading>
        <HeroPageSubheading>
          {data.download_app_page_content.page_subheading}
        </HeroPageSubheading>
        {data.download_app_page_content.page_subheading_two && (
          <HeroPageSubheading>
            {data.download_app_page_content.page_subheading_two}
          </HeroPageSubheading>
        )}
        <Badges>
          <AppleStoreBadge
            src={AppleStore}
            width={235}
            height={70}
            alt="Apple Store Badge"
            onClick={() => this.handleDownloadClick('Apple')}
          />
          <GooglePlayBadge
            src={GooglePlay}
            width={235}
            height={70}
            alt="Google Play Badge"
            onClick={() => this.handleDownloadClick('Android')}
          />
        </Badges>
        <MiniDivider />
        <NeedHelp>
          Need help? Contact Us:{' '}
          <ContactUsLink href={`mailto:${config.contact.memberservices}`}>
            {config.contact.memberservices}
          </ContactUsLink>
        </NeedHelp>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authState,
    content: state.contentState,
    trainer: state.trainerState,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent: getContent,
    },
    dispatch,
  );
}

DownloadApp.propTypes = {
  content: PropTypes.object,
  getContent: PropTypes.func,
  trainer: PropTypes.shape({
    isRetrieving: PropTypes.bool,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadApp);
