import styled from 'styled-components';

export const MemberLink = styled.button`
  align-items: center;
  background: #1e1f20;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  line-height: 24px;
  margin-top: 24px;
  padding: 12px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @media (max-width: 800px) {
    background: #ffffff;
    color: #1e1f20;
    padding: 12px 32px;
    width: auto;
  }
`;
