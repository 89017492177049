import * as ACTION from './constants';
import * as apiService from '../../services/apiServices';

export const isFetchingCustomer = () => ({ type: ACTION.GET_CUSTOMER });
export const setCustomer = (payload = null) => ({
  type: ACTION.SET_CUSTOMER,
  payload,
});
export const customerError = (error = null) => ({
  type: ACTION.CUSTOMER_ERROR,
  error,
});
export const isUpdatingCustomer = () => ({ type: ACTION.UPDATING });

export const getCustomer = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(isFetchingCustomer());
    apiService
      .getCustomer()
      .then((response) => {
        dispatch(setCustomer(response));
        resolve(response);
      })
      .catch((error) => {
        dispatch(customerError(error));
        reject(error);
      });
  });
};

export const updateCustomerPaymentMethod =
  ({ token }) =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(isUpdatingCustomer());
      apiService
        .updateCustomer({ token })
        .then((response) => {
          dispatch(setCustomer(response));
          resolve(response);
        })
        .catch((error) => {
          dispatch(customerError(error));
          reject(error);
        });
    });
  };
