import * as ACTION from './constants';

const initialState = {
  isSubscribing: false,
  isFetching: false,
  isCanceling: false,
  subscription: null,
  error: null,
  referralCode: null,
  isValidatingReferralCode: false,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.SUBSCRIPTION_BASE:
      return { ...initialState };
    case ACTION.SUBSCRIBING:
      return { ...state, isSubscribing: true };
    case ACTION.SUBSCRIPTION_SUCCESS:
      return { ...state, isSubscribing: false };
    case ACTION.SUBSCRIPTION_ERROR:
      return {
        ...state,
        isFetching: false,
        isSubscribing: false,
        error: action.error,
      };
    case ACTION.GET_SUBSCRIPTION:
      return { ...state, isFetching: true };
    case ACTION.SET_SUBSCRIPTION:
      return {
        ...state,
        isFetching: false,
        subscription: {
          ...action.payload,
        },
      };
    case ACTION.CANCELING:
      return { ...state, isCanceling: true };
    case ACTION.CANCEL_SUCCESS:
      return { ...state, isCanceling: false };
    case ACTION.CANCEL_ERROR:
      return { ...state, isCanceling: false, error: action.error };
    case ACTION.VALIDATING_REFERRAL_CODE:
      return { ...state, referralCode: null, isValidatingReferralCode: true };
    case ACTION.SET_REFERRAL_CODE:
      return {
        ...state,
        isValidatingReferralCode: false,
        referralCode: action.code,
      };
    case ACTION.REFERRAL_CODE_ERROR:
      return { ...state, isValidatingReferralCode: false, error: action.error };
    default:
      return state;
  }
}
