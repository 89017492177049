import * as ACTION from './constants';

const initialState = {
  response: null,
  isAuthenicating: false,
  isAuthenticated: false,
  userData: null,
  authError: null,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case ACTION.AUTH_BASE:
      return { ...initialState };
    case ACTION.AUTH_ERROR:
      return { ...initialState, authError: action.error };
    case ACTION.AUTHENTICATING:
      return {
        ...state,
        isAuthenicating: true,
        authError: null,
      };
    case ACTION.AUTH_SUCCESS:
      if (action.payload.token) {
        // tbd
        localStorage.setItem('apiToken', action.payload.token);
      }
      return {
        ...state,
        isAuthenicating: false,
        isAuthenticated: true,
        userData: action.payload,
        authError: null,
      };
    default:
      return state;
  }
}
