import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const HeroPageSubheading = styled.div`
  color: #747679;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (max-width: 800px) {
    margin-top: 24px;
  }
`;

export const MiniDivider = styled.div`
  border: 1px solid #e3e6e8;
  margin: 8px 0;
  width: 132px;
`;

export const CorporateLink = styled(Link)`
  align-items: center;
  color: #1e1f20;
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 12px 0;
  text-decoration: none;
`;

export const SignUpLink = styled(Link)`
  color: #caa854;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
`;

export const HeroPageHeading = styled.div`
  color: #1e1f20;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 56px;
  margin-bottom: 12px;
  text-align: center;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const NeedHelp = styled.div`
  color: #1e1f20;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
`;

export const ContactUsLink = styled.a`
  color: #1e1f20;
  font-weight: 600;
`;

export const AppleStoreBadge = styled.img`
  border: 0px solid #ffffff;
`;

export const GooglePlayBadge = styled.img`
  border: 1px solid #a6a6a6;
  border-radius: 11px;
`;

export const Badges = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 32px 0;

  img {
    margin: 8px 0;
    cursor: pointer;
  }
`;
