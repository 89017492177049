import React from 'react';
import { Log, OidcClient } from 'oidc-client';

import { MemberLink } from './styles.js';

import config from '../../../core/config';

class SSOLoginButton extends React.Component {
  async login() {
    Log.logger = console;
    Log.level = Log.INFO;

    const settings = {
      authority: `${config.sso.url}/api/oidc`,
      client_id: config.sso.clientId,
      loadUserInfo: false,
      redirect_uri: config.sso.redirectUri,
      silent_redirect_uri: config.sso.redirectUri,
      response_type: 'code',
      response_mode: 'query',
      scope: 'openid email profile offline_access',
      prompt: 'consent',
      post_logout_redirect_uri: config.base_href,
    };

    const oidcClient = new OidcClient(settings);
    const signinRequest = await oidcClient.createSigninRequest();
    window.location.href = signinRequest.url;
  }

  render() {
    return (
      <MemberLink onClick={this.login}>Sign Into My Member Account</MemberLink>
    );
  }
}

export default SSOLoginButton;
