import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from '../modules';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  const finalCreateStore = composeEnhancers(applyMiddleware(thunkMiddleware))(
    createStore,
  );
  const store = finalCreateStore(rootReducer, initialState);

  return store;
}
