export const NAMESPACE = 'subscription';
export const SUBSCRIPTION_BASE = `${NAMESPACE}/SUBSCRIPTION_BASE`;
export const SUBSCRIBING = `${NAMESPACE}/SUBSCRIBING`;
export const SUBSCRIPTION_SUCCESS = `${NAMESPACE}/SUBSCRIPTION_SUCCESS`;
export const SUBSCRIPTION_ERROR = `${NAMESPACE}/SUBSCRIPTION_ERROR`;
export const GET_SUBSCRIPTION = `${NAMESPACE}/GET_SUBSCRIPTION`;
export const SET_SUBSCRIPTION = `${NAMESPACE}/SET_SUBSCRIPTION`;
export const CANCELING = `${NAMESPACE}/CANCELING`;
export const CANCEL_SUCCESS = `${NAMESPACE}/CANCEL_SUCCESS`;
export const CANCEL_ERROR = `${NAMESPACE}/CANCEL_ERROR`;
export const VALIDATING_REFERRAL_CODE = `${NAMESPACE}/VALIDATING_REFERRAL_CODE`;
export const SET_REFERRAL_CODE = `${NAMESPACE}/SET_REFERRAL_CODE`;
export const REFERRAL_CODE_ERROR = `${NAMESPACE}/REFERRAL_CODE_ERROR`;
