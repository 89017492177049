import styled from 'styled-components';

export const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 75em;
  padding: 20px 0;
  width: 80%;
`;

export const Content = styled.div`
  p {
    letter-spacing: 0.1em;
    line-height: 1.8;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  ol,
  p,
  ul {
    letter-spacing: 0.1em;
    line-height: 1.45;
    margin-bottom: 1.45rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }

  .grid,
  h3,
  h4,
  h4,
  h6,
  p {
    text-indent: 0;
  }

  .grid {
    border-left: 0.0625em solid #001c2e;
    border-top: 0.0625em solid #001c2e;
    display: grid;
    margin-bottom: 1.45rem;
    overflow-x: auto;
  }
  .grid > div {
    border-bottom: 0.0625em solid #001c2e;
    border-right: 0.0625em solid #001c2e;
    padding: 10px 15px;
  }
  .grid-cookies {
    grid-template-columns: repeat(3, auto);
  }
  .grid-header {
    font-weight: 400;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #1e1f20;
  }

  tbody tr,
  tbody td {
    border: 1px solid #1e1f20;
  }
`;
