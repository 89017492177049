import * as ACTION from './constants';
import { getAccountSettings } from '../Settings';
import { default as AuthManager } from '../../core/authManager';

// ACTION CREATORS
export const setUserData = (payload = null) => ({
  type: ACTION.AUTH_SUCCESS,
  payload,
});
export const authBase = () => ({ type: ACTION.AUTH_BASE });
export const isAuthenticating = () => ({ type: ACTION.AUTHENTICATING });
export const authError = (error = null) => ({ type: ACTION.AUTH_ERROR, error });

export const exchangeCode = (code, code_verifier, trainer) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(isAuthenticating());
    AuthManager.exchangeCode(code, code_verifier, trainer)
      .then((response) => {
        dispatch(setUserData(response));
        dispatch(getAccountSettings());
        resolve(response);
      })
      .catch((error) => {
        dispatch(
          authError(
            error && error.response ? error.response.data : error.message,
          ),
        );
        reject(error);
      });
  });
};

export const signOut = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const authState = getState().authState;
    const userToken = authState.userData.token;
    AuthManager.signOut(userToken)
      .then((response) => {
        dispatch(authBase());
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
