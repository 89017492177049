import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardElement } from 'react-stripe-elements';
import { withStyles } from '@material-ui/styles';
import * as thm from '../../../theme';

const cardInputStyles = {
  base: {
    color: '#32325D',
    fontWeight: 500,
    fontFamily: 'Inter UI, Open Sans, Segoe UI, sans-serif',
    fontSize: '16px',
    fontSmoothing: 'antialiased',

    '::placeholder': {
      color: '#CFD7DF',
    },
  },
  invalid: {
    color: '#E25950',
  },
};

const styles = {
  focus: {
    'background-color': `${thm.BASE_COLORS.white}`,
    color: `${thm.BASE_COLORS.black}`,
  },
  blur: {
    'background-color': `${thm.BASE_COLORS.B5}`,
    color: `${thm.FONT_COLORS.LIGHT_DISABLED}`,
  },
};

class CardBodyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      cardClasses: { card: 'blur' },
      complete: false,
    };
  }

  onCardElementChange = (changeObject) => {
    if (this.props.onChange) this.props.onChange(changeObject);
  };

  onCardElementBlur = (changeObject) => {
    const { elementType } = changeObject;
    const { cardClasses } = this.state;
    if (this.props.onBlur) this.props.onBlur(changeObject);
    cardClasses[elementType] = 'blur';
    this.setState({ cardClasses });
  };

  onCardElementFocus = (changeObject) => {
    const { elementType } = changeObject;
    const { cardClasses } = this.state;
    if (this.props.onFocus) this.props.onFocus(changeObject);
    cardClasses[elementType] = 'focus';
    this.setState({ cardClasses });
  };

  render() {
    const { classes, ...other } = this.props;
    const { cardClasses } = this.state;

    return (
      <CardElement
        style={cardInputStyles}
        className={classes[cardClasses.card]}
        onBlur={this.onCardElementBlur}
        onFocus={this.onCardElementFocus}
        onChange={this.onCardElementChange}
        {...other}
      />
    );
  }
}

CardBodyForm.propTypes = {
  classes: PropTypes.shape({
    blur: PropTypes.string,
    focus: PropTypes.string,
  }),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

export default withStyles(styles)(CardBodyForm);
