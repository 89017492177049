import styled from 'styled-components';

import { Link } from 'react-router-dom';

import { Button, withStyles } from '@material-ui/core';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';
import DescriptionIcon from '@material-ui/icons/Description';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import CardBodyForm from '../../components/molecule/CardBodyForm';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 42px 64px 0;

  @media (max-width: 800px) {
    padding: 24px;
  }
`;

export const Header = styled.div`
  align-items: flex-start;
  border-bottom: 0.5px solid #e3e6e8;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 24px;
  width: 100%;
`;

export const Title = styled.div`
  color: #1e1f20;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 56px;

  @media (max-width: 800px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const SettingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: minmax(400px, 100vh);
  margin-top: 20px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  position: relative;
`;

export const LinksWrapper = styled.div`
  margin-left: 125px;
  width: 275px;

  @media (max-width: 800px) {
    border-top: 0.5px solid #e3e6e8;
    margin: 8px auto;
    max-width: 400px;
    width: 100%;
  }
`;

export const ButtonStyled = styled(Button)`
  && {
    background: #f0f2f3;
    border-radius: 4px;
    color: #1e1f20;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 32px;
    text-transform: none;
  }
`;

export const LinkStyled = styled.a`
  align-items: center;
  background: #f0f2f3;
  border-radius: 4px;
  color: #1e1f20;
  display: flex;
  flex-direction: row;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  justify-content: center;
  line-height: 24px;
  margin-bottom: 16px;
  padding: 12px 24px;
  text-decoration: none;
`;

export const ContactUsStyled = styled(LinkStyled)`
  @media (max-width: 800px) {
    margin-top: 24px;
  }
`;

export const LogoutIconStyled = styled(ExitToAppIcon)`
  margin-right: 10px;
`;

export const MailIconStyled = styled(MailIcon)`
  margin-right: 10px;
`;
export const LockIconStyled = styled(LockIcon)`
  margin-right: 10px;
`;
export const DescriptionIconStyled = styled(DescriptionIcon)`
  margin-right: 10px;
`;

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:first-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    marginBottom: '12px',
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    background: 'rgba(240, 242, 243, 0.5)',
    color: '#1e1f20',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',

    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
  expandIcon: {
    order: 1,
  },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export const FieldWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FieldRow = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin-bottom: 20px;
  margin-left: 38px;

  @media (max-width: 800px) {
    align-items: flex-start;
    flex-direction: column;
    margin-left: 12px;
    width: 100%;
  }
`;

export const FieldLabel = styled.div`
  color: #1e1f20;
  flex: 1;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
`;

export const FieldValue = styled.div`
  color: #1e1f20;
  flex: 1;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  padding: 4px 0;
`;

export const FieldValueWrapper = styled.div`
  display: flex;
  flex: 2;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const EditFieldLink = styled.a`
  color: #1e1f20;
  cursor: pointer;
  flex: 1;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  text-decoration: underline;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const StartNewSubscriptionLink = styled(Link)`
  color: #1e1f20;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
`;

export const CancelSubscriptionLink = styled.a`
  color: #bc3d3d;
  cursor: pointer;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
`;

export const ModalContentContainer = styled.div`
  padding: 0;
`;

export const ModalTitle = styled.div`
  color: #1e1f20;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 8px;
`;

export const ModalSubtitle = styled.div`
  color: #6f6f6f;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 24px;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalKeepButton = styled(Button)`
  && {
    background: #1e1f20;
    border: 1px solid #1e1f20;
    border-radius: 4px;
    color: #ffffff;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    padding: 12px 32px;
    text-transform: none;

    &:hover {
      background: #1e1f20;
      color: #ffffff;
    }
  }
`;

export const ModalCancelButton = styled(Button)`
  && {
    border: 1px solid #1e1f20;
    border-radius: 4px;
    color: #1e1f20;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding: 12px 32px;
    text-transform: none;
  }
`;

export const CardElementStyled = styled.div`
  border-radius: 3px;
  padding: 4px 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
`;

export const CardElementWrapper = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const CardBodyElementStyled = styled(CardBodyForm)`
  background: #ffffff;
  border: ${(props) =>
    props.errorMessage ? '1px solid #bc3d3d' : '1px solid #dadada'};
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 8px;
  padding: 8px;
  flex: 1;
`;

export const ErrorMessageContainer = styled.div`
  background: #bc3d3d;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 8px;
  margin: 2px 0 16px 0;
`;

export const UpdateButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const UpdateButton = styled(Button)`
  && {
    background: #1e1f20;
    border-radius: 4px;
    color: #ffffff;
    padding: 6px 2px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 0;
    text-transform: none;
    width: 100%;
    &:hover {
      background: #1e1f20;
      color: #ffffff;
    }
  }
`;

export const DisabledUpdateButton = styled(Button)`
  && {
    background: #f0f2f3;
    color: #747679;
    padding: 6px 2px;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 8px 0;
    text-transform: none;
    width: 100%;
    &:hover {
      background: #f0f2f3;
      color: ##747679;
    }
  }
`;

export const CancelButton = styled(Button)`
  && {
    border: 1px solid #1e1f20;
    border-radius: 4px;
    color: #1e1f20;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 4px;
    padding: 8px 0;
    text-transform: none;
  }
`;

export const TransparentLoading = styled.div`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
`;
