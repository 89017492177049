import { darken } from 'polished';

export const FONT_FAMILIES = {
  ROBOTO_MEDIUM: 'Roboto-Medium',
  ROBOTO_REGULAR: 'Roboto-Regular',
  ROBOTO_MONO_MEDIUM: 'RobotoMono-Medium',
};

export const FONT_SIZE = {
  xxs: '8px',
  xs: '12px',
  sm: '14px',
  rg: '16px',
  lg: '18px',
  xl: '24px',
  xxl: '36px',
  headline: '24px',
  title: '20px',
  subheader: '16px',
  body: '14px',
  caption: '12px',
};

export const FONT_COLORS = {
  gray: '#979797',
  white: '#FFFFFF',
  blue: '#0495F2',
  light_blue: '#00EAFF',
  red: '#AC3731',
  DARK_PRIMARY: 'rgba(0,0,0,1)',
  DARK_SECONDARY: 'rgba(0,0,0,0.54)',
  DARK_DISABLED: 'rgba(0,0,0,0.38)',
  LIGHT_PRIMARY: 'rgba(255,255,255,1)',
  LIGHT_SECONDARY: 'rgba(255,255,255,0.70)',
  LIGHT_DISABLED: 'rgba(255,255,255,0.30)',
};

export const FONT_WEIGHT = {
  slim: '200',
  normal: '400',
  medium: '500',
  bold: '600',
};

export const FONT_STYLES = {
  CHECKBOX_LIGHT: `
    font-size: ${FONT_SIZE.rg};
    color: ${FONT_COLORS.LIGHT_PRIMARY};
    line-height: 19;
    letter-spacing: -0.02;
  `,
  CHECKBOX_DARK: `
    font-size: ${FONT_SIZE.rg};
    color: ${FONT_COLORS.DARK_PRIMARY};
    line-height: 19;
    letter-spacing: -0.02;
  `,
};

export const BASE_COLORS = {
  white: '#FFFFFF',
  gray: '#303030',
  light_gray: '#CACACA',
  medium_gray: '#C4C4C4',
  black: '#171717',
  blue: '#0495F2',
  dark_blue: darken(0.1, '#0495F2'),
  clear: 'rgba(0,0,0,0)',
  glass_gray: 'rgba(163,163,163,0.3)',
  glass_white: 'rgba(255, 255, 255, .3)',
  alert: '#FC00FF',
  pink: '#F9E9E9',
  dark_pink: darken(0.1, '#F9E9E9'),
  green: '#64BF7B',
  dark_green: darken(0.1, '#64BF7B'),
  stripe_button: '#d782d9',
  stripe_button_hover: darken(0.1, '#d782d9'),
  C1: '#F8CB46',
  C2: '#D388B3',
  C3: '#78C4CB',
  C4: '#BE5497',
  C5: '#1F1547',
  C6: '#1F1547',
  C7: '#5882AC',
  C8: '#93C8BC',
  G1: '#f2f2f2',
  B5: '#3b3b3b',
  B6: '#2F80ED',
  B7: '#EBF1F7',
};

export const BASE_SCREEN_VALUES = {
  headerSpace: 50,
};

export const SCREEN_GRADIENTS = {
  Home: ['#58ADF6', '#001DAC'],
  Achievement: ['#aa4b6b', '#3b8d99'],
  Browse: ['#00F260', '#0575E6'],
  Browse_Programs: ['#BE5869', '#403A3E'],
  Browse_Workouts: ['#1CB5E0', '#000046'],
  Browse_Trainers: ['#99f2c8', '#1f4037'],
  Settings: ['#bdc3c7', '#2c3e50'],
};

export const BUTTON_GRADIENTS = {
  B1: ['#3C8CE7', '#00EAFF'],
  B2: ['#28C76F', '#81FBB8'],
  B3: ['#000DFF', '#6B73FF'],
  B4: ['#90F7EC', '#32CCBC'],
  A1: ['#00DBDE', '#FC00FF'],
};

export const PILL_BOX_GRADIENTS = {
  F1: ['#21D4FD', '#B721FF'],
  F2: ['#6018DC', '#EEAD92'],
  F3: ['#736EFE', '#5EFCE8'],
  F4: ['#3813C2', '#FF6FD8'],
  F5: ['#F80BBF', '#77C1EC'],
  F6: ['#1904E5', '#FAB2FF'],
  F7: ['#130CB7', '#52E5E7'],
  F8: ['#B210FF', '#EECE13'],
};

export const GRADIENTS = {
  P1: ['#12FFF7', '#B3FFAB'],
  HEADER: ['rgba(142, 206, 232,0.09)', 'rgba(92,151,177,0.9)'],
};

export const BACKGROUND_GRADIENT =
  'linear-gradient(30.89deg, #00EAFF -2.36%, #3C8CE7 75.62%)';

export const BOX_SHADOWS = {
  S1: 'rgba(142, 206, 232, 1)',
  S2: '#F1AB23',
  S3: 'rgba(31, 21, 71, 0.54)',
  S4: '#FFFFFF',
  S5: 'rgba(114, 133, 166, 0.35)',
};

export const EXERCISE_VIDEO = {
  BACKGROUND: '#000000',
};

export const REST_FILL_COLORS = {
  START_FADE: '#FF0000',
  END_FADE: 'rgba(0,0,0,0.55)',
};

export const EXERCISE_FILLER = {
  ACTIVE_FILL: 'rgba(142, 206, 232, 0.6)',
  INACTIVE_FILL: 'rgba(163, 163, 163, 0.6)',
  BACKGROUND: 'rgba(255,255,255,0.1)',
};

export const CHECKBOX = {
  EMPTY: '#E4E4E48A',
  CHECKED: '#8ECEE8',
};
