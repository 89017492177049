import * as ACTION from './constants';
import * as apiService from '../../services/apiServices';
import { data as trainer_data } from '../../core/trainer_data';

export const isRetrievingTrainer = () => ({ type: ACTION.GET_TRAINER });
export const setTrainer = (trainer = null) => ({
  type: ACTION.SET_TRAINER,
  trainer,
});
export const setTrainerGeneric = (generic) => ({
  type: ACTION.SET_TRAINER_GENERIC,
  generic,
});
export const finishedRetrieving = () => ({ type: ACTION.FINISHED_RETRIEVING });

export const lookupTrainerBySlug = (data, promoCode) => (dispatch) => {
  dispatch(isRetrievingTrainer());
  return new Promise((resolve, reject) => {
    if (trainer_data[data] || trainer_data.aliases[data]) {
      const this_slug = trainer_data[data] ? data : trainer_data.aliases[data];
      const this_data = {
        channel_slug: this_slug,
        promoCode,
        ...trainer_data[this_slug],
      };

      if (promoCode) {
        apiService
          .validatePromoCode(promoCode)
          .then((response) => {
            this_data.promo = { ...response.data };
            dispatch(setTrainer(this_data));
            resolve(this_data);
          })
          .catch(() => {
            dispatch(setTrainer(this_data));
            resolve(this_data);
          });
      } else {
        Promise.resolve(this_data).then((response) => {
          dispatch(setTrainer(response));
          resolve(response);
        });
      }
    } else {
      dispatch(finishedRetrieving());
      reject();
    }
  });
};

export const lookupTrainerBySite = (data, promoCode) => (dispatch) => {
  dispatch(isRetrievingTrainer());
  return new Promise((resolve, reject) => {
    if (trainer_data.sites[data]) {
      const channel_slug = trainer_data.sites[data].trainer_slug;
      const this_data = {
        channel_slug,
        site_data: {
          slug: data,
          ...trainer_data.sites[data],
        },
        promoCode,
        ...trainer_data[channel_slug],
      };

      if (promoCode) {
        apiService
          .validatePromoCode(promoCode)
          .then((response) => {
            this_data.promo = { ...response.data };
            dispatch(setTrainer(this_data));
            resolve(this_data);
          })
          .catch(() => {
            dispatch(setTrainer(this_data));
            resolve(this_data);
          });
      } else {
        Promise.resolve(this_data).then((response) => {
          dispatch(setTrainer(response));
          resolve(response);
        });
      }
    } else {
      dispatch(finishedRetrieving());
      reject();
    }
  });
};

export const setTrainerFromStore = (data) => (dispatch) => {
  dispatch(setTrainer(data));
};
